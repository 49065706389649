import { AbstractControl } from "@angular/forms";

export interface LoginFormDataInterface {
  email: string;
  password: string;
  user: string;
}

export const CART_KEY = "cartItems";
export const ORDER_DETAILS = "orderDetails";
export const TEMP_ORDERS = "tempOrders";
export const CONVERSION_KEY = "conversion";
export const DELIVERY_FEE = 100;
export const MAX_PRICE_FOR_DELIVERY = 2000; // max price for free

export interface Notifications {
  senderId: number;
  extras: string;
  message: string;
  createdAt: Date;
  senderType: string;
  receipt: string;
  senderName: string;
  length?: number;
}

export const CURRENCY = "$";
export const ApiUrl = {
  dev: "",
  local: "http://localhost/server/asbil-backend/router.php/", // http://localhost/server/asbil-backend/router.php/
  prod: "",
};

export const PRODUCT_DETAIL_PATH = "/shop/product/urun/";
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const passwordMismatch = (
  control: AbstractControl
): { [key: string]: boolean } => {
  const password = control.get("password");
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  const confirm_password = control.get("confirm_password");
  if (!password || !confirm_password) {
    return null;
  }
  const d =
    password.value === confirm_password.value ? null : { mismatch: true };
  console.log(d);
  return d;
};

export const NAME_REGEX = /[`~!@#$%^&*()_|+\-=?;:'",.<>\s\{\}\[\]\\\/]/gi;
