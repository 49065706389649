<div class="media">
	<a [routerLink]="['/shop/product/no/sidebar/', product.STOKNO]">
		<img
			[defaultImage]="
				ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'
			"
			[lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src"
			class="img-fluid lazy-loading"
			alt="{{ product.images[0].alt }}"
		/>
	</a>
	<div class="media-body align-self-center">
		<div class="rating">
			<bar-rating [rate]="5" [readOnly]="true"></bar-rating>
			<a [routerLink]="['/shop/product/no/sidebar/', product.STOKNO]">
				<h6>{{ product.ADI | titlecase }}</h6>
			</a>
		</div>

		<h4 *ngIf="showPrice">
			{{ product?.SATISFIYATI3 | currency: currency?.currency:'symbol' }}
		</h4>
		<!--        <ul class="color-variant">-->
		<!--            <li [class]="color" -->
		<!--                *ngFor="let color of Color(product?.variants)" -->
		<!--                [ngStyle]="{'background-color': color}"-->
		<!--                (click)="ChangeVariants(color, product)">-->
		<!--            </li>-->
		<!--        </ul>-->
	</div>
</div>
