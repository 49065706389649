<div class="icon-nav mobile-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="searchToggle()">
        <i class="ti-search"></i>
      </div>
      <div id="search-overlay" class="search-overlay" [ngStyle]="{ display: search ? 'block' : 'none' }">
        <div>
          <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
          <div class="overlay-content">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <form>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Search a Product"
                      />
                    </div>
                    <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>

    <li class="onhover-div user-icon" id="loginDiv" *ngIf="!user">
      <div class="inner-icon-wrapper">
        <a href="javascript:void(0)">
          <span class="icon-desc">Giriş Yap</span>
          <span class="fa fa-user-circle"></span>
        </a>
      </div>
      <ul class="login-reg user-login onhover-show-div">
        <li class="">
          <a routerLink="/auth/uye-giris"> Üye Giriş </a>
        </li>
        <li class="">
          <a (click)="navigate('')">Üye Ol</a>
        </li>
      </ul>
    </li>
    <li class="account-div user-icon" *ngIf="user">
      <div class="inner-icon-wrapper">
        <a href="javascript:void(0)" (click)="navigateUserAccount()">
          <span class="icon-desc">Hesabim</span>
          <span class="fa fa-user-circle"></span>
        </a>
      </div>
    </li>

    <li class="onhover-div mobile-cart cart-icon" id="myCart" [routerLink]="['/shop/cart']">
      <div class="inner-icon-wrapper">
        <a href="javascript:void(0)">
          <span class="icon-desc">Sepetim</span>
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ cartItems.length }}</span>
    </li>
  </ul>
</div>
