<form class="form_search" role="form">
  <input
    aria-expanded="false"
    autocomplete="off"
    class="nav-search nav-search-field"
    id="query search-autocomplete"
    placeholder="Ürün kodu ya da adı yazıp arayabilirsiniz"
    type="text"
    [(ngModel)]="searchModel"
    [ngbTypeahead]="search"
    [resultTemplate]="rt"
    [inputFormatter]="formatter"
    (selectItem)="itemE($event)"
    name="searchText"
    [disabled]="!products"
    [focusFirst]="false"
    (keyup.enter)="onEnter($event)"
  />
  <!--	#headerProductSearch-->

  <button class="btn-search" name="nav-submit-button" type="button" (click)="searchPage()">
    <i class="ti-search"></i>
  </button>

  <ng-template #rt let-r="result" let-t="term" class="search-list">
    <div class="d-flex">
      <img
        [src]="r.images.length > 0 ? r.images[0].src : 'assets/images/product/placeholder.jpg'"
        alt=""
        class="mr-2 prod-image"
        (click)="navigateSearch($event, r)"
      />
      <div class="d-flex flex-column">
        <ngb-highlight [result]="r.WEBACIKLAMA" [term]="t" (click)="navigateSearch($event, r)"></ngb-highlight>
        <ngb-highlight [result]="r.STOKNO" [term]="t"></ngb-highlight>
      </div>
    </div>
  </ng-template>
</form>
