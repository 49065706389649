<!-- Add to cart modal popup start-->
<ng-template #pictureModal class="picture-modal" let-modal>
	<div class="modal-content">
		<div class="close-btn" (click)="closeModal()">
			<i class="ti-close"></i>
		</div>

		<div class="wrapper">
			<div class="mySlides" *ngFor="let item of product.IMAGES">
				<img [src]="item.src" style="width: 100%" />
			</div>
			<!--   <div class="mySlides"  >
               <img src="assets/images/product/placeholder.jpg" style="width: 100%">
           </div>
           <div class="mySlides"  >
               <img src="assets/images/product/placeholder.jpg" style="width: 100%">
           </div>
           <div class="mySlides"  >
               <img src="assets/images/product/placeholder.jpg" style="width: 100%">
           </div> -->
			<a class="prev">&#10094;</a>
			<a class="next">&#10095;</a>
		</div>

		<div class="thumbnail-wrapper" *ngIf="product.IMAGES.length > 1">
			<div class="column">
				<img
					class="demo cursor"
					src="assets/images/product/placeholder.jpg"
					style="width: 100%"
					[alt]=""
				/>
			</div>
			<div class="column">
				<img
					class="demo cursor"
					src="assets/images/product/placeholder.jpg"
					style="width: 100%"
					[alt]=""
				/>
			</div>
			<div class="column">
				<img
					class="demo cursor"
					src="assets/images/product/placeholder.jpg"
					style="width: 100%"
					[alt]=""
				/>
			</div>
			<div class="column">
				<img
					class="demo cursor"
					src="assets/images/product/placeholder.jpg"
					style="width: 100%"
					[alt]=""
				/>
			</div>
		</div>
	</div>
</ng-template>

<!-- Add to cart modal popup end-->
