import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../services/product.service';
import { Product } from '../../../classes/product';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { User } from '../../../classes/user';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PRODUCT_DETAIL_PATH } from '../../../interfaces/interface';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { HelperService } from '../../../services/helper.service';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
})
export class HeaderSearchComponent implements OnInit {
  searchProducts: Product[] = [];
  public searchText;
  public stick = false;
  isSearch: boolean;
  notFound: boolean;
  public notesText: string;
  subject = new Subject();
  public greetings = '';
  user: User;
  // @HostListener Decorator
  searchModel: any;
  products: Product[] = [];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private helperService: HelperService,
    private productService: ProductService
  ) {}

  onEnter(e) {
    e.preventDefault();
    this.searchPage();
  }

  async itemE(e) {
    console.log(e);
    await this.router
      .navigate([`${PRODUCT_DETAIL_PATH}`, `${e.item.STOKNO}`], {
        relativeTo: this.route,
        queryParams: {
          slug: e.item.STOKNO.replace(' ', '-'),
        },
        // queryParamsHandling: 'merge', // preserve the existing query params in the route
        skipLocationChange: false, // do trigger navigation
      })
      .then(() => {
        this.searchModel = null;
      });
    this.searchText = '';
    this.searchProducts = [];
  }

  ngOnInit(): void {
    this.productService.ProductCached.subscribe((data) => {
      this.products = data;
    });
  }

  search: OperatorFunction<string, readonly Product[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(700),
      distinctUntilChanged(),
      map((searchValue) => {
        const term = searchValue.trim();
        this.searchText = term;
        return term === ''
          ? this.products
          : term.length < 2
          ? []
          : this.products
              .filter((v) => {
                // JSON.stringify(v).toLocaleLowerCase().includes(term.toLocaleLowerCase())
                const lowerCaseTerm = this.helperService.localeLowerCase(term);
                return (
                  this.helperService.localeLowerCase(v.WEBACIKLAMA).includes(lowerCaseTerm) ||
                  this.helperService.localeLowerCase(v.STOKNO).includes(lowerCaseTerm)
                );
              })
              .slice(0, 10);
      })
    );
  formatter = (x: { WEBACIKLAMA: string }) => x.WEBACIKLAMA;

  async navigateSearch(e, product) {
    console.log(e, product);
    // await this.router
    //   .navigate([`${PRODUCT_DETAIL_PATH}`, `${product.item.STOKNO}`], {
    //     relativeTo: this.route,
    //     queryParams: {
    //       slug: product.item.STOKNO.replace(' ', '-'),
    //     },
    //     // queryParamsHandling: 'merge', // preserve the existing query params in the route
    //     skipLocationChange: false, // do trigger navigation
    //   })
    //   .then(() => {
    //     this.searchModel = null;
    //   });
    // this.searchText = '';
    // this.searchProducts = [];
  }

  searchPage() {
    const term = this.searchModel;

    this.router
      .navigate(['/shop/search'], {
        queryParams: {
          q: term,
        },
      })
      .catch((er) => {
        console.log(er);
      });
  }
}
