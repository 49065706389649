import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { toInteger } from "lodash";
import { UserType } from "../../classes/user";
import { HelperService } from "../../services/helper.service";

@Component({
  selector: "app-delivery-fee",
  templateUrl: "./delivery-fee.component.html",
  styleUrls: ["./delivery-fee.component.scss"],
})
export class DeliveryFeeComponent implements OnInit {
  @Input() TotalCartPrice: number;
  @Input() usdTotal;
  @Input() tlTotal;
  @Input() user;
  deliveryFee = 0;

  constructor(
    public helperService: HelperService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.user && this.user.userType === UserType.retailer) {
      this.deliveryFee = 0;
      return;
    }
    this.helperService.emitIsDeliveryFee$.subscribe((data) => {
      this.deliveryFee = data;
    });
  }

  /* getDeliveryFee(addFee = true) {
     return this.helperService.emitIsDeliveryFee$;
     /!* const conv =
        this.usdTotal *
        JSON.parse(localStorage.getItem("conversion")).data.rate ?? 0;
      const totalWithOutDelivery = toInteger(conv + this.tlTotal);
      this.deliveryFee = addFee ? (totalWithOutDelivery > 500 ? 0 : 30) : 0;*!/
   }*/
}
