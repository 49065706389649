import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {QuickViewComponent} from '../../modal/quick-view/quick-view.component';
import {CartModalComponent} from '../../modal/cart-modal/cart-modal.component';
import {Product} from '../../../classes/product';
import {ProductService} from '../../../services/product.service';
import {CartService} from '../../../services/cart.service';

@Component({
    selector: 'app-product-box-two',
    templateUrl: './product-box-two.component.html',
    styleUrls: ['./product-box-two.component.scss'],
})
export class ProductBoxTwoComponent implements OnInit {
    @Input() product: Product;
    @Input() currency: any = this.productService.Currency; // Default Currency
    @Input() cartModal = false; // Default False

    @ViewChild('quickView') QuickView: QuickViewComponent;
    @ViewChild('cartModal') CartModal: CartModalComponent;

    public ImageSrc: string;

    constructor(
        private productService: ProductService,
        private cartService: CartService,
    ) {
    }

    ngOnInit(): void {
    }

    addToCart(product: any) {
        this.cartService.addToCart(product);
    }

    addToWishlist(product: any) {
        this.productService.addToWishlist(product);
    }

    addToCompare(product: any) {
        this.productService.addToCompare(product);
    }
}
