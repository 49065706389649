<div class="container-fluid align-items-center pr-0 pl-0 grid-wrapper-inner">
  <div class="product-top-filter" [class.fixMe]="fixMe" #fixItem>
    <div class="row" [ngClass]="fixMe ? 'justify-content-center' : ''">
      <div [ngClass]="fixMe ? 'col-md-7' : 'col-12'">
        <div class="product-filter-content">
          <!--<div class="search-count">
                         <h5>
                            Ürün Gösteriliyor 1-{{ products.length }} of
                            {{ paginate.totalItems }} Sonuç
                        </h5>
                    </div> -->

          <div class="collection-view">
            <ul>
              <li
                (click)="setLayoutView('grid-view')"
                [ngClass]="{ active: layoutView == 'grid-view' }"
              >
                <i class="fa fa-th grid-layout-view"></i>
              </li>
              <li
                (click)="setLayoutView('list-view')"
                [ngClass]="{ active: layoutView == 'list-view' }"
              >
                <i class="fa fa-list-ul list-layout-view"></i>
              </li>
              <li class="available-stock">
                <input
                  type="checkbox"
                  [disabled]="disableCheck"
                  [checked]="showAOnlyAvailableStock"
                  [(ngModel)]="showAOnlyAvailableStock"
                  class="show-available-stock"
                  (change)="showAvailableStock()"
                />
                Stoktaki Ürünler
              </li>
            </ul>
          </div>

          <div class="product-page-filter">
            <ul>
              <li class="">
                <select
                  (change)="sortName($event)"
                  class="form-control marka-filter custom-select"
                >
                  <option value="" disabled selected>Marka Sıralı</option>
                  <option value="a-z" [selected]="sortBy == 'a-z'">
                    A'dan Z'ye Sıralı
                  </option>
                  <option value="z-a" [selected]="sortBy == 'z-a'">
                    Z'dan A'ye Sıralı
                  </option>
                </select>
              </li>

              <li>
                <select
                  (change)="sortPrice($event)"
                  class="form-control price-filter custom-select"
                >
                  <option value="" disabled selected>Fiyata Göre</option>
                  <option value="low" [selected]="sortBy == 'low'">
                    Fiyat Artan
                  </option>
                  <option value="high" [selected]="sortBy == 'high'">
                    Fiyat Azalan
                  </option>
                </select>
              </li>

              <li>
                <select
                  (change)="sortStock($event)"
                  class="form-control stock-filter custom-select"
                >
                  <option value="" selected disabled>Stok Sıralı</option>
                  <option
                    value="high-low-stok"
                    [selected]="sortBy == 'high-low-stok'"
                  >
                    Stok Artan
                  </option>
                  <option
                    value="low-high-stok"
                    [selected]="sortBy == 'low-high-stok'"
                  >
                    Stok Azalan
                  </option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
