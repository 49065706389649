<!-- Add to cart modal popup start-->
<ng-template #cartModal let-modal>
    <div class="modal-header ">
        <div class="d-flex justify-content-between  w-100">
            <h3 class="">{{ product.WEBACIKLAMA }}</h3>
            <div>
                <button
                        (click)="modal.dismiss('Cross click')"
                        class="close"
                        id="close-cart-modal"
                        type="button"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div class="modal-bg addtocart">

                        <div class="media">
                            <a
                                    *ngIf="product.images"
                                    [routerLink]="['/shop/product/left/sidebar/', product.ADI]"
                            >
                                <img
                                        [alt]="product.images[0].alt"
                                        [src]="product.images[0].src"
                                        class="img-fluid pro-img"
                                />
                            </a>
                            <div class="media-body align-self-center text-center">
                                <div class="stocks">

                                    <!-- Magusa stock start                                -->
                                    <div class="row">
                                        <div class="col-3 stocks">
                                            <div class="stock-location">Magusa Stok :</div>
                                        </div>
                                        <div class="col-1 stocks">
                                            <div class="stock-amount">
                                                {{ product.MBAKIYE }}
                                            </div>
                                        </div>
                                        <div class="col-6 stocks">
                                         <!--   <select (change)="updateQuantity($event, product, magusaStock)"
                                                    class="custom-select form-control">
                                                <option></option>
                                                <option
                                                        *ngFor="let qty of [].constructor(product.MBAKIYE); let x = index"
                                                        [value]="x+1">
                                                    {{x + 1}}
                                                </option>
                                            </select>-->
                                            <div class="qty-box">
                                                <div class="input-group">
                                                    <input [disabled]="+product.MBAKIYE === 0"
                                                           [(ngModel)]="magusaQuantity"
                                                           class="form-control input-number"
                                                           name="quantity"
                                                           type="tel"
                                                    />
                                                    <span class="input-group-prepend">
														<button
                                                                [disabled]="+product.MBAKIYE === 0"
                                                                [class.disabled]=" +product.MBAKIYE === 0 "
                                                                (click)="updateQuantity(magusaQuantity, product,magusaStock)"
                                                                class="btn quantity-right-plus"
                                                                data-type="plus"
                                                                type="button"
                                                        >
															Sepete ekle
														</button>
													</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Lefkosa stock start                                -->
                                    <div class="row mt-3">
                                        <div class="col-3 stocks">
                                            <div class="">Lefkosa Stok :</div>
                                        </div>
                                        <div class="col-1 stocks">
                                            {{ product.LBAKIYE }}
                                        </div>
                                        <div class="col-6 stocks">
                                            <div class="qty-box">
                                                <div class="input-group">
													<!--<span class="input-group-prepend">
														<button
                                                                (click)="decrement(lefkosaStock)"
                                                                [disabled]="lefkosaStockCounter === 0"
                                                                class="btn quantity-left-minus"
                                                                data-type="minus"
                                                                type="button"
                                                        >
															<i class="ti-angle-left"></i>
														</button>
													</span>-->
                                                    <input [disabled]="+product.LBAKIYE === 0"
                                                            [(ngModel)]="lefkosaQuantity"
                                                            class="form-control input-number"
                                                            name="quantity"
                                                            type="tel"
                                                    />
                                                    <span class="input-group-prepend">
														<button
                                                                [disabled]="+product.LBAKIYE === 0"
                                                                [class.disabled]=" +product.LBAKIYE === 0 "
                                                                (click)="updateQuantity(lefkosaQuantity, product,lefkosaStock)"
                                                                class="btn quantity-right-plus"
                                                                data-type="plus"
                                                                type="button"
                                                        >
															Sepete ekle
														</button>
													</span>
                                                </div>
                                            </div>
                                            <!--<select (change)="updateQuantity($event, product, lefkosaStock)"
                                                    class="custom-select form-control">
                                                <option></option>
                                                <option *ngFor="let qty of [].constructor(product.LBAKIYE); let x = index"
                                                        [value]="x+1">
                                                    {{x + 1}}
                                                </option>
                                            </select>-->
                                        </div>
                                        <!--  <div class="col-4 stocks">
                                       <div class="cart-btn-wrapper">
                                               <a
                                                       (click)="addToCart(product, lefkosaStock)"
                                                       [class.disabled]="
                                                       lefkosaStockCounter > product.LBAKIYE
                                                   "
                                                       class="btn btn-solid"
                                               > Sepete Ekle</a
                                               >
                                           </div>
                                        </div>-->
                                    </div>
                                </div>
                                <!--  <div class="buttons">
                                      <a [routerLink]="['/shop/cart']" class="view-cart btn btn-solid">Your cart</a>
                                      <a [routerLink]="['/shop/checkout']" class="checkout btn btn-solid">Check out</a>
                                      <a [routerLink]="['/shop/collection/left/sidebar']" class="continue btn btn-solid">Continue shopping</a>
                                  </div>-->

                                <!--                                <div class="upsell_payment">-->
                                <!--                                    <img src="assets/images/payment_cart.png" class="img-fluid" alt="">-->
                                <!--                                </div>-->
                            </div>
                        </div>

                        <!--  <div class="product-section">
                              <div class="col-12 product-upsell text-center">
                                  <h4>Customers who bought this item also.</h4>
                              </div>
                              <div class="row" id="upsell_product">
                                  <ng-container *ngFor="let product of products | slice:0:4">
                                      <div class="product-box col-sm-3 col-6">
                                          <div class="img-wrapper">
                                              <div class="front">
                                                  <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
                                                      <img [src]="product.images[0].src" class="img-fluid mb-1" alt="">
                                                  </a>
                                              </div>
                                              <div class="product-detail">
                                                  <h6 class="mt-0">
                                                      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]"><span>{{ product.title | titlecase }}</span></a>
                                                  </h6>
                                                  <h4><span>{{ product.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}</span></h4>
                                              </div>
                                          </div>
                                      </div>
                                  </ng-container>
                              </div>
                          </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Add to cart modal popup end-->
