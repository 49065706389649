import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { delay, map, withLatestFrom } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ProductService } from "./shared/services/product.service";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { environment } from "../environments/environment";
import { LogIn, UserListener } from "./_store/actions/auth.action";
import { AppState, selectAuthState } from "./_store/app.states";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
declare var anime: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1])
  );

  showLoading = false; //environment.production;
  getState: Observable<any>;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    translate: TranslateService,
    private productService: ProductService,
    analytics: AngularFireAnalytics,
    private store: Store<AppState>
  ) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang("tr");
      translate.addLangs(["en", "tr"]);
    }
    analytics
      .logEvent("custom_event", {})
      .then((log) => {
        // console.log(log);
      })
      .catch((e) => {
        console.log(e);
      });

    if (window.location.pathname.includes("product/urun")) {
      this.showLoading = false;
    }
    const subLoad = this.loaders.subscribe((e) => {
      if (e >= 36) {
        this.showLoading = false;
      }

      if (e > 60 || e < 1) {
        document.getElementById("body").style.height = "auto";
        document.getElementById("body").style.overflow = "initial";

        setTimeout(() => {
          const textWrapper = document.querySelector(".an-1");
          if (textWrapper) {
            textWrapper.innerHTML = textWrapper.textContent.replace(
              /\S/g,
              "<span class='letter'>$&</span>"
            );

            anime.timeline({ loop: false }).add({
              targets: ".an-1 .letter",
              scale: [4, 1],
              opacity: [0, 1],
              translateZ: 0,
              easing: "easeOutExpo",
              duration: 1000,
              delay: (el, i) => 100 * i,
            });
          }
        }, 500);
        subLoad.unsubscribe();
      }
    });
    this.getState = this.store.select(selectAuthState);

    this.startUserListener();
  }

  private startUserListener() {
    this.store.dispatch(UserListener(null));
  }

  ngOnInit() {
    // this.productService.initProduct();
  }
}
