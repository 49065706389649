<app-header [sticky]="true"></app-header>

<app-breadcrumb [title]="''" [breadcrumb]="'Magazalar'"></app-breadcrumb>
<!--section start-->
<section class="contact-page section-b-space">
  <div class="container">
    <div class="row section-b-space">
      <div class="col-lg-5">
        <div class="contact-right">
          <h3 class="contact-header">İRTİBAT - MAGUSA</h3>
          <ul>
            <li>
              <div class="contact-icon">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </div>
              <div class="media-body">
                <p>
                  Eşref Bitlis Cad. Mesan Apt. C-Blok Dükkan-8, Serbest Liman
                  Yolu, Gazimağusa – KKTC
                </p>
              </div>
            </li>
            <li>
              <div class="contact-icon">
                <img
                  src="assets/images/icon/phone.png"
                  alt="Generic placeholder image"
                />
              </div>
              <div class="media-body">
                <p>+90 392 365 13 58-590</p>
              </div>
            </li>

            <li>
              <div class="contact-icon">
                <!--                <img src="assets/images/icon/email.png" alt="Generic placeholder image" />-->
                <img
                  src="assets/images/icon/phone.png"
                  alt="Generic placeholder image"
                />
              </div>
              <div class="media-body">
                <p>+90 548 850 98 88</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7 map">
        <!--                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d50059.12775918716!2d72.78534673554945!3d21.16564923510817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1533793756956"   allowfullscreen></iframe>-->
        <iframe
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=ASB%C4%B0L%20Bili%C5%9Fim%20Teknolojileri%20LTD.,%20E%C5%9Fref%20Bitlis%20Caddesi,%20Ma%C4%9Fusa%20Port%20Residence%20no:2,%20Mersin%2010%20Turkey%20-%20North+(Asbil%20)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </div>
    </div>
    <div class="row section-b-space">
      <div class="col-lg-5">
        <div class="contact-right">
          <h3 class="contact-header">İRTİBAT - LEFKOŞA</h3>
          <ul>
            <li>
              <div class="contact-icon">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </div>
              <div class="media-body">
                <p>
                  Atatürk Caddesi, Candemir 7 Apt. No:26 - Dükkan A
                  Yenişehir/Lefkoşa
                </p>
              </div>
            </li>
            <li>
              <div class="contact-icon">
                <img
                  src="assets/images/icon/phone.png"
                  alt="Generic placeholder image"
                />
              </div>
              <div class="media-body">
                <p>+90 392 227 31 77</p>
              </div>
            </li>

            <li>
              <div class="contact-icon">
                <img
                  src="assets/images/icon/phone.png"
                  alt="Generic placeholder image"
                />
              </div>
              <div class="media-body">
                <p>+90 548 850 98 90</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7 map">
        <!--                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d50059.12775918716!2d72.78534673554945!3d21.16564923510817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1533793756956"   allowfullscreen></iframe>-->
        <!-- <iframe
           src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=ASB%C4%B0L%20Bili%C5%9Fim%20Teknolojileri%20LTD.,%20E%C5%9Fref%20Bitlis%20Caddesi,%20Ma%C4%9Fusa%20Port%20Residence%20no:2,%20Mersin%2010%20Turkey%20-%20North+(Asbil%20)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
         ></iframe>-->
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18445.74013037745!2d33.368403086866664!3d35.18711067309531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14de177dda5d7b2b%3A0x4b77ca0d4045a90e!2sASB%C4%B0L%20Bili%C5%9Fim%20Teknolojileri%20LTD.!5e0!3m2!1sen!2s!4v1646737430987!5m2!1sen!2s"
          width="600"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</section>
<!--Section ends-->
<app-footer-two></app-footer-two>
