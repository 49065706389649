import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ShopComponent } from './shop/shop.component';

import { LoginComponent } from './auth/login/login.component';
import { LoginComponent as AdminLoginComponent } from './admin/components/auth/login/login.component';
import { AccountComponent } from './account/account.component';
import { SuccessComponent } from './shop/checkout/tab-success/success.component';
import { AuthGuard } from './shared/guard/auth-guard';
import { AdminComponent } from './admin/admin.component';
import { CorporateComponent } from './shop/corporate/corporate.component';
import { ContactComponent } from './shop/contact/contact.component';
import { ShopLocationsComponent } from './shop/shop-locations/shop-locations.component';
import { AboutUsComponent } from './shop/about-us/about-us.component';
import { PaynetRedirectResponseComponent } from './_paynet/paynet-redirect-response/paynet-redirect-response.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'shop',
    component: ShopComponent,
    loadChildren: () => import('./shop/shop.module').then((m) => m.ShopModule),
  },
  {
    path: 'admin',
    component: AdminComponent,
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'auth/admin',
    component: AdminLoginComponent,
    loadChildren: () => import('./admin/components/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'checkout/redirect',
    component: PaynetRedirectResponseComponent,
  },

  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'uye-giris',
    component: LoginComponent,
  },
  {
    path: 'c/:type',
    component: CorporateComponent,
  },
  {
    path: 'iletisim',
    component: ContactComponent,
  },
  {
    path: 'magazalar',
    component: ShopLocationsComponent,
  },
  {
    path: 'hakkimizda',
    component: AboutUsComponent,
  },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      useHash: false,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
