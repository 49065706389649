<header [class.fixed]="stick && sticky" [ngClass]="class" class="">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="top-menu border-section border-top-0">
          <div class="d-flex mbn">
            <app-mobile-menu></app-mobile-menu>
            <div class="brand-logo mobile-logo">
              <a [routerLink]="['/']">
                <img [src]="themeLogo" alt="logo" class="img-fluid" />
              </a>
            </div>
            <div>
              <app-mobile-rightside-nav></app-mobile-rightside-nav>
            </div>
          </div>
          <app-header-search style="width: 100%"></app-header-search>
        </div>
      </div>
    </div>
  </div>
</header>
