<ng-container *ngIf="checkUser(user, origin); else showOrder">
  <ng-container *ngIf="product.discountToTL && +product.discountToTL > 0; else NoDiscount">
    {{ +product.discountToTL * quantityAdded | currency: product.conversionCurrency:'symbol-narrow' }}

    <del> {{ product.conversionToTL | currency: product?.conversionCurrency:'symbol-narrow' }}</del>
  </ng-container>

  <ng-template #NoDiscount>
    {{ +product?.conversionToTL * quantityAdded | currency: product?.conversionCurrency:'symbol-narrow' }}
  </ng-template>
</ng-container>

<ng-template #showOrder>
  <ng-container *ngIf="product?.discountPrice && +product.discountPrice > 0; else NoDiscount">
    {{ product?.discountPrice * quantityAdded | currency: product?.currency:'symbol-narrow' }}
    <del> {{ product?.displayPrice | currency: product?.currency:'symbol-narrow' }}</del>
  </ng-container>

  <ng-template #NoDiscount>
    {{ product.displayPrice * quantityAdded | currency: product?.currency:'symbol-narrow' }}
  </ng-template>
</ng-template>
