import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {Product} from '../../classes/product';

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit {
    @Input() products: Product[] = [];
    @Input() paginate: any = {};
    @Input() layoutView = 'grid-view';
    @Input() sortBy: string;
    @Input() showAOnlyAvailableStock: boolean;
    @Input() disableCheck: boolean;
    @ViewChild('fixItem') fixItem: ElementRef;

    @Output() setGrid: EventEmitter<any> = new EventEmitter<any>();
    @Output() setLayout: EventEmitter<any> = new EventEmitter<any>();

    @Output() sortedByName: EventEmitter<any> = new EventEmitter<any>();
    @Output() sortedByPrice: EventEmitter<any> = new EventEmitter<any>();
    @Output() sortedByStock: EventEmitter<any> = new EventEmitter<any>();

    @Output() setAvailable: EventEmitter<boolean> = new EventEmitter<boolean>(true);
    stockAvailable = false;
    public fixMe: boolean;

    constructor(private element: ElementRef, private renderer: Renderer2) {
    }

    ngOnInit(): void {
    }

    // @HostListener Decorator
    @HostListener('window:scroll', [])
    onWindowScroll() {
        const num =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;
        if (num > 100) {
            this.fixMe = true;
        } else {
            this.fixMe = false;
        }
    }

    setGridLayout(value: string) {
        this.setGrid.emit(value); // Set Grid Size
    }

    showAvailableStock() {
        this.setAvailable.emit(this.showAOnlyAvailableStock);
        return;
    }

    setLayoutView(value: string) {
        this.layoutView = value;
        this.setLayout.emit(value); // Set layout view
    }

    sortName(event) {
        this.sortedByName.emit(event.target.value);
    }

    sortPrice(event) {
        this.sortedByPrice.emit(event.target.value);
    }

    sortStock(event) {
        this.sortedByStock.emit(event.target.value);
    }
}
