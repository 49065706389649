import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  Input,
  AfterViewInit,
  Injectable,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '../../../services/product.service';
import { Product } from '../../../classes/product';
import { StockLocations } from '../../../classes/stock-locations.enum';
import { ToastService } from 'src/app/shared/services/toast.service';
import { CartService } from '../../../services/cart.service';

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.component.html',
  styleUrls: ['./cart-modal.component.scss'],
})
export class CartModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() product: Product;
  @Input() currency: any;

  @ViewChild('cartModal', { static: false }) CartModal: TemplateRef<any>;
  public magusaStock = StockLocations.magusa;
  public lefkosaStock = StockLocations.lefkosa;
  public magusaStockCounter = 0;
  public lefkosaStockCounter = 0;

  public closeResult: string;
  public modalOpen = false;
  public products: any[] = [];
  counter = 0;
  lefkosaQuantity = 0;
  magusaQuantity = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private modalService: NgbModal,
    private productService: ProductService,
    private toastService: ToastService,
    private cartService: CartService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  async openModal(product) {
    // await this.productService.getProducts.subscribe(response => this.products = response);
    // this.products = await this.products.filter(items => items.KAT2 === product.KAT2  && items.id !== product.id);
    // const status = await this.productService.addToCart(product);
    console.log(this.product);
    this.product.LBAKIYE = +this.product.LBAKIYE < 0 ? 1 : +this.product.LBAKIYE; // stock in lefkosa
    this.product.MBAKIYE = +this.product.MBAKIYE < 0 ? 1 : +this.product.MBAKIYE; // stock in magusa
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.CartModal, {
          size: 'lg',
          ariaLabelledBy: 'Cart-Modal',
          centered: true,
          windowClass: 'theme-modal cart-modal CartModal',
        })
        .result.then(
        (result) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          `Result ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  updateQuantity(quantity, product, stockLocation) {
    // const quantity = e.target.value;
    if (quantity < 1) {
      this.toastService.warningToast('Lütfen ürün miktarını artırın');
      return;
    }

    if (stockLocation === StockLocations.lefkosa && product.LBAKIYE < quantity) {
      return;
    } else if (stockLocation === StockLocations.magusa && product.MBAKIYE < quantity) {
      return;
    }
    if (stockLocation === StockLocations.lefkosa && quantity > product.LBAKIYE) {
      return;
    } else if (stockLocation === StockLocations.magusa && quantity > product.MBAKIYE) {
      return;
    }

    product.quantity = quantity;
    this.cartService.addToCart(product, quantity, stockLocation);
  }
}
