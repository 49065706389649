import { Injectable } from "@angular/core";
import { Cart } from "../classes/cart";
import { StockLocations } from "../classes/stock-locations.enum";
import { Product } from "../classes/product";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ToastService } from "./toast.service";
import { ProductService } from "./product.service";
import {
  CART_KEY,
  CONVERSION_KEY,
  DELIVERY_FEE,
  MAX_PRICE_FOR_DELIVERY,
} from "../interfaces/interface";
import { AccountService } from ".";
import { ApiService } from "./api.service";
import { Store } from "@ngrx/store";
import { selectCart } from "../../_store/app.states";
import { isEmpty, isNumber, toInteger } from "lodash";
import { HelperService } from "./helper.service";
import { UserType } from "../classes/user";

const state = {
  products: JSON.parse(localStorage.products || "[]"),
  wishlist: JSON.parse(localStorage.wishlistItems || "[]"),
  compare: JSON.parse(localStorage.compareItems || "[]"),
  cart: JSON.parse(localStorage.cartItems || "[]"),
  currentUser: JSON.parse(localStorage.currentUser || "{}"),
};

export const CURRENCIES = {
  TRL: ["TRY", "TRL"],
  USD: ["USD"],
};

@Injectable({
  providedIn: "root",
})
export class CartService {
  public OpenCart = false;
  myCartItems = new BehaviorSubject([]);
  currentUser: any;
  constructor(
    private helperService: HelperService,
    private toastService: ToastService,
    private productService: ProductService,
    private accountService: AccountService,
    private apiService: ApiService,
    private store: Store
  ) {
    this.accountService.getAccountState().subscribe((res) => {
      if (res) {
        this.currentUser = res;
      }
    });
    this.store.select(selectCart).subscribe((res: any) => {
      if (res.cartData) {
        this.myCartItems = res.cartData;
      }
    });
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    /* if (this.currentUser && this.currentUser.isAuthenticated) {
       this.apiService
         .upsert(this.userCartRef, {})
         .then()
         .catch((e) => console.log(e));
     } else {

     }*/
    const index = state.cart.indexOf(product);
    state.cart.splice(index, 1);
    localStorage.setItem(CART_KEY, JSON.stringify(state.cart));
    // return true;
  }

  private isUser() {
    return this.currentUser && this.currentUser.isAuthenticated;
  }

  private get userCartRef() {
    return `carts/${this.currentUser.user.userType}-${this.currentUser.user.id}`;
  }

  public deleteCart() {
    /*  if (this.isUser()) {
        this.apiService.delete(this.userCartRef).catch(e => console.log(e));
      } else {

      }*/
    if (state.cart?.length > 0) {
      for (let i = 0; i <= state.cart.length; i++) {
        state.cart.splice(i);
      }
    }
    localStorage.setItem(CART_KEY, JSON.stringify(state.cart));

    return true;
  }

  getCartItems() {
    return Promise.resolve(JSON.parse(localStorage.cartItems || "[]"));
  }

  async addToCartTwo(product: Product, quantity: number, showAlert?: boolean) {
    const cartItems = state.cart.find((item) => item.STOKNO === product.STOKNO);

    if (cartItems) {
      return this.updateCartQuantityTwo(product, quantity);
    }
    state.cart.push({
      ...product,
      quantity,
      // stockLocation
    });
    localStorage.setItem(CART_KEY, JSON.stringify(state.cart));

    // this.OpenCart = true;
    if (showAlert) {
      this.toastService.successToast("ürün sepete eklendi");
    }
  }

  /*For vendors*/
  public addToCart(
    product: any,
    quantity?: number,
    stockLocation?: StockLocations
  ): any {
    // get items in cart

    let isNewAdd = false;

    // check if cart contians product and location
    const cartItem: Cart = state.cart.find(
      (item) =>
        item.STOKNO === product.STOKNO && item.stockLocation === stockLocation
    );

    if (!cartItem) {
      isNewAdd = true; // new item is being added
    }

    if (isNewAdd) {
      state.cart.push({
        ...product,
        quantity,
        stockLocation,
      });
      localStorage.setItem(CART_KEY, JSON.stringify(state.cart));
      // sho
      this.toastService.successToast("ürün sepete eklendi");
    } else {
      this.updateCartQuantity(product, quantity, stockLocation);
    }

    return true;
  }

  // Get Cart Items
  public get cartItems(): Observable<Cart[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.cart);
      observer.complete();
    });
    return itemsStream as Observable<Cart[]>;
  }

  public getCartTotalItems(): Observable<number> {
    return this.cartItems.pipe(
      map((product: Cart[]) => {
        return product.reduce((prev, curr: Cart) => {
          const qty = +curr.quantity;
          return +prev + qty;
        }, 0);
      })
    );
  }

  /*Update cart for vendor*/
  public updateCartQuantity(
    product: Cart,
    quantity: number,
    stockLocation: string
  ): Product | boolean {
    // console.log()
    return state.cart.find((item, index) => {
      // check if product to be added is already in cart
      if (
        item.STOKNO === product.STOKNO &&
        item.stockLocation === stockLocation
      ) {
        state.cart[index].quantity = quantity;
        localStorage.setItem(CART_KEY, JSON.stringify(state.cart));
        this.toastService.successToast("Sepet güncellendi");
        return true;
      }
    });
  }

  private CurrencyTotal = (product: Cart[]) =>
    product.reduce((prev, curr: Cart) => {
      const price =
        curr.discountPrice && curr.discountPrice > 0
          ? curr.discountPrice
          : curr.displayPrice;
      return prev + price * curr.quantity;
    }, 0);

  // Total amount
  public cartTotalAmount(currency): Observable<number> {
    return this.cartItems.pipe(
      map((product: Cart[]) => {
        if (CURRENCIES.USD.includes(currency)) {
          const USDProducts = product.filter((item) =>
            CURRENCIES.USD.includes(item.currency)
          );
          return this.CurrencyTotal(USDProducts);
        }
        if (CURRENCIES.TRL.includes(currency)) {
          const TRYProducts = product.filter((item) =>
            CURRENCIES.TRL.includes(item.currency)
          );
          return this.CurrencyTotal(TRYProducts);
        }
      })
    );
  }

  private calculateTotal(product: any[], conversionRate) {
    return product.reduce((prev, curr: Cart) => {
      let priceInTL = 0;
      if (CURRENCIES.TRL.includes(curr.currency)) {
        priceInTL =
          curr.discountPrice && curr.discountPrice > 0
            ? curr.discountPrice
            : curr.displayPrice;
      }
      if (CURRENCIES.USD.includes(curr.currency)) {
        priceInTL = priceInTL + conversionRate * curr.displayPrice;
      }
      return toInteger(prev + priceInTL * curr.quantity);
    }, 0);
  }

  // Update Cart Quantity cor customers
  public updateCartQuantityTwo(product: Product, quantity: number) {
    return state.cart.find((items, index) => {
      if (items.STOKNO === product.STOKNO) {
        state.cart[index].quantity = +quantity;
        localStorage.setItem(CART_KEY, JSON.stringify(state.cart));
        this.toastService.successToast("Sepet güncellendi");
        return true;
      }
    });
  }

  // Add to Cart

  /* @args items as cart Items quantity as number of products to be added*/
  public calculateStockCounts(cartItems, quantity, product: Product) {
    // new product added
    // check if total quantity exceeds quantity added and quantity to be added
    // const qty = product.quantity + quantity;
    const stock = product.stock;
    if (stock < quantity || stock === 0) {
      this.toastService.errorToast(
        "You can not add more items than available. In stock " +
          stock +
          " items."
      );
      return false;
    }
    return true;
  }

  public checkItemsInCart(cartItems: Cart[], user, useConversion = true) {
    const rate = JSON.parse(localStorage.getItem(CONVERSION_KEY));
    return this.apiService
      .post(`prepareCartProducts`, {
        method: "prepareCartProducts",
        route: "product",
        data: {
          user: user ?? null,
          ids: cartItems.map((it) => it.STOKNO),
          cartItems: cartItems.map((it) => {
            return {
              quantity: +it.quantity,
              STOKNO: it.STOKNO,
            };
          }),
          convertPricesToTl: useConversion ? useConversion : null,
          rater: rate ? rate.data : null,
        },
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        map((response) => {
          if (response.result === "OK") {
            // return this.productService.transformProduct(response.data);
            return {
              data: response.data,
              cartTotal: response.data,
            };
          }
          return null;
        })
      );
  }

  public async prepareCartOrder(user, checkDeliveryOption = null) {
    return await new Promise((resolve, reject) => {
      this.cartItems.subscribe(
        async (response) => {
          this.checkItemsInCart(response, user).subscribe((cartItemResult) => {
            if (cartItemResult.data) {
              const cartItems = [];
              response.map((item) => {
                const product = cartItemResult.data.find(
                  (i) => i.STOKNO === item.STOKNO
                );
                if (product) {
                  item.LBAKIYE = +product.LBAKIYE;
                  item.MBAKIYE = +product.MBAKIYE;
                  item.stock = +product.stock;
                  item.discountPrice = +product.discountPrice;
                  item.currency = product.currency;

                  cartItems.push({
                    ...product,
                    ...item,
                  });
                }
              });

              const isTRY = cartItems.some((item) =>
                CURRENCIES.TRL.includes(item.currency)
              );
              const isUSD = cartItems.some((item) =>
                CURRENCIES.USD.includes(item.currency)
              );

              if (!cartItems.length || cartItems.length === 0) {
                reject("no cart");
              }
              const conversion =
                JSON.parse(localStorage.getItem(CONVERSION_KEY))?.data?.rate ??
                0;
              let totalAmount = this.calculateTotal(cartItems, conversion);

              // if not user or guest or user but not retailer
              if (
                isEmpty(user) ||
                (Object.keys(user).length > 0 &&
                  user?.userType !== UserType.retailer)
              ) {
                if (
                  checkDeliveryOption &&
                  checkDeliveryOption.deliveryOption !== "Adrese Teslim"
                ) {
                  totalAmount = totalAmount + 0;
                } else {
                  totalAmount =
                    this.helperService.addDeliveredAmount(totalAmount);
                }
              }
              console.log(totalAmount);

              const cartData = {
                isTRY,
                isUSD,
                products: cartItems,
                totalAmount,
              };
              resolve(cartData);
            }
            reject("no cart");
          });
        },
        (err) => reject("no cart")
      );
    });
  }

  createCart(values) {
    console.log(values);
    return Promise.resolve(values);
    // return this.apiService.upsert(this.userCartRef, { user: this.currentUser.user });
  }

  updateCart(cartItems: any) {
    return this.apiService.upsert(this.userCartRef, { cartItems });
  }

  /*Data contains {quantity, products, t*/
  aggregateCartItems(data: any, cartItems: any[]) {
    const quantity = Number(data.quantity);
    const product = data.product;

    const updatedProduct = cartItems.find(
      (pro) => pro.STOKNO === product.STOKNO
    );

    if (!isEmpty(updatedProduct) && quantity === updatedProduct.quantity) {
      return {};
    }
    if (product.stock < quantity) {
      return {};
    }

    if (data.stocK) {
      if (!isNumber(quantity)) {
        return {};
      }
    }
    if (quantity <= 0) {
      this.removeCartItem(product);
      return {};
    }

    if (
      product.stockLocation === StockLocations.lefkosa &&
      product.LBAKIYE < quantity
    ) {
      this.toastMessage("Lütfen ürün miktarını artırın");
      return {};
    } else if (
      product.stockLocation === StockLocations.magusa &&
      product.MBAKIYE < quantity
    ) {
      this.toastMessage("Lütfen ürün miktarını artırın");
      return {};
    }
    if (
      product.stockLocation === StockLocations.lefkosa &&
      quantity > product.LBAKIYE
    ) {
      this.toastMessage("Lütfen ürün miktarını artırın");
      return {};
    } else if (
      product.stockLocation === StockLocations.magusa &&
      quantity > product.MBAKIYE
    ) {
      this.toastMessage("Lütfen ürün miktarını artırın");
      return {};
    }
    return {
      quantity,
      product,
    };
  }

  private toastMessage(message: string) {
    this.toastService.showToast("warning", message);
  }
}
