import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-price-conversions',
  templateUrl: './price-conversions.component.html',
  styleUrls: ['./price-conversions.component.scss'],
})
export class PriceConversionsComponent implements OnInit {
  @Input() PriceData: any;
  conversionRate: any;
  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    this.getPriceConversions();
  }

  private getPriceConversions(): void {
    this.helperService.getConversionRate(this.PriceData).subscribe((resp) => {
      console.log(resp);
      this.conversionRate = resp.data.conversion;
    });
  }
}
