import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { BarRatingModule } from "ngx-bar-rating";
import {
  LAZYLOAD_IMAGE_HOOKS,
  LazyLoadImageModule,
  ScrollHooks,
} from "ng-lazyload-image";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { TranslateModule } from "@ngx-translate/core";

// Header and Footer Components
import { HeaderOneComponent } from "./header/header/header-one.component";
import { FooterOneComponent } from "./footer/footer-one/footer-one.component";
import { FooterTwoComponent } from "./footer/footer-two/footer-two.component";

// Components
import { LeftMenuComponent } from "./components/left-menu/left-menu.component";
import { MenuComponent } from "./components/brands-menu/menu.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { CategoriesComponent } from "./components/categories/categories.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { ProductBoxOneComponent } from "./components/product/product-box-one/product-box-one.component";
import { ProductBoxTwoComponent } from "./components/product/product-box-two/product-box-two.component";
import { ProductBoxThreeComponent } from "./components/product/product-box-three/product-box-three.component";
import { ProductBoxFourComponent } from "./components/product/product-box-four/product-box-four.component";
import { ProductBoxFiveComponent } from "./components/product/product-box-five/product-box-five.component";
import { ProductBoxVerticalComponent } from "./components/product/product-box-vertical/product-box-vertical.component";
import { ProductBoxVerticalSliderComponent } from "./components/product/product-box-vertical-slider/product-box-vertical-slider.component";

// Modals Components
import { NewsletterComponent } from "./components/modal/newsletter/newsletter.component";
import { QuickViewComponent } from "./components/modal/quick-view/quick-view.component";
import { CartModalComponent } from "./components/modal/cart-modal/cart-modal.component";
import { CartVariationComponent } from "./components/modal/cart-variation/cart-variation.component";
import { VideoModalComponent } from "./components/modal/video-modal/video-modal.component";
import { SizeModalComponent } from "./components/modal/size-modal/size-modal.component";
import { AgeVerificationComponent } from "./components/modal/age-verification/age-verification.component";

// Skeleton Loader Components
import { SkeletonProductBoxComponent } from "./components/skeleton/skeleton-product-box/skeleton-product-box.component";

// Layout Box
import { LayoutBoxComponent } from "./components/layout-box/layout-box.component";

// Tap To Top
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";

// Pipes
import { DiscountPipe } from "./pipes/discount.pipe";
import { PictureModalComponent } from "./components/modal/picture-modal/picture-modal-component";
import { SearchFilterPipe } from "./pipes/searchFilter.pipe";
import { HighlightDirective } from "./directives/highlight.directive";
import { ProductModalComponent } from "./components/modal/product/product-modal.component";
import { FeaturesComponent } from "./widgets/features/features.component";
import { BrandsComponent } from "./widgets/brands/brands.component";
import { PriceComponent } from "./widgets/price/price.component";
import { GridComponent } from "./widgets/grid/grid.component";
import { SelectedItemComponent } from "./components/pc-build/selected-item.component";
import { SizeComponent } from "./widgets/size/size.component";
import { Ng5SliderModule } from "ng5-slider";
import { BillingAddressComponent } from "../shop/checkout/billing-address/billing-address.component";
import { CheckoutCartComponent } from "../shop/checkout/checkout-cart/checkout-cart.component";
import { DisplayDiscountComponent } from "./components/display-discount/display-discount.component";
import { DisplayTlConversionComponent } from "./components/display-tl-conversion/display-tl-conversion.component";
import { PriceConversionsComponent } from "./components/price-conversions/price-conversions.component";
import { DeliveryFeeComponent } from "./widgets/delivery-fee/delivery-fee.component";
import { ConvertTotalPriceComponent } from "./widgets/convert-total-price/convert-total-price.component";
import { RatioModalComponent } from "./components/modal/ratio-modal/ratio-modal.component";
import { MobileHeaderComponent } from "./header/mobile-header/mobile-header.component";
import { ResponsiveModule } from "ngx-responsive";
import { HeaderAccountNavComponent } from "./header/components/header-account-nav/header-account-nav.component";
import { HeaderSearchComponent } from "./header/components/header-search/header-search.component";
import { MobileMenuComponent } from "./header/mobile-menu/mobile-menu.component";
import { MobileRightsideNavComponent } from "./header/mobile-rightside-nav/mobile-rightside-nav.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { ModalProductViewsComponent } from "./components/modal/product/modal-product-views/modal-product-views.component";
import { LoadingDataComponent } from "./components/loading-data/loading-data.component";

@NgModule({
  declarations: [
    HeaderOneComponent,
    FooterOneComponent,
    FooterTwoComponent,
    LeftMenuComponent,
    MenuComponent,
    SettingsComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    PictureModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    SearchFilterPipe,
    HighlightDirective,
    ProductModalComponent,
    FeaturesComponent,
    BrandsComponent,
    PriceComponent,
    GridComponent,
    SelectedItemComponent,
    SizeComponent,
    BillingAddressComponent,
    CheckoutCartComponent,
    DisplayDiscountComponent,
    DisplayTlConversionComponent,
    PriceConversionsComponent,
    DeliveryFeeComponent,
    ConvertTotalPriceComponent,
    RatioModalComponent,
    MobileHeaderComponent,
    HeaderAccountNavComponent,
    HeaderSearchComponent,
    MobileMenuComponent,
    MobileRightsideNavComponent,
    LoaderComponent,
    ModalProductViewsComponent,
    LoadingDataComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    Ng5SliderModule,
    LazyLoadImageModule,
    ResponsiveModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    HeaderOneComponent,
    FooterOneComponent,
    FooterTwoComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    PictureModalComponent,
    ProductModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    SearchFilterPipe,
    HighlightDirective,
    FeaturesComponent,
    BrandsComponent,
    PriceComponent,
    GridComponent,
    SelectedItemComponent,
    SizeComponent,
    BillingAddressComponent,
    CheckoutCartComponent,
    DisplayDiscountComponent,
    DisplayTlConversionComponent,
    PriceConversionsComponent,
    DeliveryFeeComponent,
    ConvertTotalPriceComponent,
    RatioModalComponent,
    MobileHeaderComponent,
    HeaderSearchComponent,
    LoaderComponent,
    LoadingDataComponent,
  ],
  providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }],
})
export class SharedModule {}
