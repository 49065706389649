import { Injectable } from "@angular/core";
// import firebase from 'firebase/app';
import { ApiService } from "./api.service";
import { ApiUrl } from "../../admin/shared/interface/interface";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SiteSettingService {
  constructor(private apiService: ApiService) {}

  getHomePageSlides() {
    return this.apiService.colWithIds$("homePage");
  }

  /*  getHomePageSlides() {
      return this.apiService
        .post(`${ApiUrl.prod}siteSettings`, {
          method: 'getHomePageBanner',
          route: 'admin',
        })
        .pipe(
          map((res) => {
            if (res.data) {
              return res.data;
              // this.preloadImages(res.data)
            }
            return [];
          })
        );
    }*/

  getCorporateData(contentType: string) {
    return this.apiService.doc$(`corporateData/${contentType}`);
  }

  get getFreeShippingFee() {
    return this.apiService.doc$(`shopServices/freeShippingFee`);
  }
  preloadImages(sources) {
    var images = {};
    var loadedImages = 0;
    var numImages = 0;
    for (var src in sources) {
      numImages++;
    }
    for (var src in sources) {
      images[src] = new Image();
      images[src].onload = function () {
        if (++loadedImages >= numImages) {
          // callback(images);
        }
      };
      images[src].src = sources[src];
    }
  }
}
