import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private toast: ToastrService) {
    toast.toastrConfig.positionClass = "toast-top-left";
  }

  successToast(
    message?: string,
    position = "toast-top-left",
    timeOut?: number
  ) {
    this.toast.success(message, "", {
      closeButton: true,
      positionClass: position,
      timeOut: timeOut || 4000,
    });
  }

  warningToast(message?: string, position = "toast-top-left") {
    this.toast.warning(message, "", {
      closeButton: true,
      positionClass: position,
    });
  }

  infoToast(message?: string, position = "toast-top-left") {
    this.toast.info(message, "", {
      closeButton: true,
    });
  }

  errorToast(message?: string, position = "toast-top-left", timeOut?: number) {
    this.toast.error(message, "", {
      closeButton: true,
      positionClass: position,
      timeOut: timeOut || 4000,
    });
  }
  showToast(
    type: "warning" | "error" | "success",
    message?: string,
    position = "toast-top-left",
    timeOut?: number
  ) {
    this.toast[type](message, "", {
      closeButton: true,
      positionClass: position,
      timeOut: timeOut || 4000,
    });
  }
}
