<h4 class="d-flex justify-content-between align-items-center mb-3">
  <span class="text-muted">Sepetim</span>
  <!--    <span class="badge badge-secondary badge-pill">{{products.length}}</span>-->
</h4>
<ul *ngIf="products.length > 0" class="list-group mb-3 my-cart">
  <li
    *ngFor="let product of products | slice: 0:showLimit"
    class="list-group-item d-flex justify-content-between lh-condensed align-items-center"
  >
    <div>
      <h6 class="my-0">{{ product.WEBACIKLAMA }}</h6>
      <small class="text-muted quantity"> × {{ product.quantity }} adet</small>
      <small
        *ngIf="product.stock <= 0"
        class="asbil-ml-[2rem] asbil-text-red-500"
        >STOK YOK</small
      >
    </div>
    <span class="text-muted">
      <app-display-discount
        [user]="user"
        [origin]="'checkOut'"
        [product]="product"
      ></app-display-discount
    ></span>
  </li>
  <ng-container *ngIf="products.length > showLimit">
    <li class="list-group-item w-100 text-center">
      <div (click)="showAllProducts(products)" class="btn btn-link">
        {{ products.length - 4 }} Ürün daha
      </div>
    </li>
  </ng-container>

  <ng-container *ngIf="user?.userType === userType.retailer">
    <li *ngIf="isTRY" class="list-group-item d-flex justify-content-between">
      <span>Toplam (TRY)</span>
      <strong>{{ tlTotal | currency: "TRY":"symbol-narrow" }}</strong>
    </li>
    <li *ngIf="isUSD" class="list-group-item d-flex justify-content-between">
      <span>Toplam (USD)</span>
      <strong>{{ usdTotal | currency: "USD":"symbol" }}</strong>
    </li>
  </ng-container>
  <li class="list-group-item d-flex justify-content-between">
    <span>Kargo Bedeli </span>
    <strong>
      <app-delivery-fee
        [user]="user"
        [tlTotal]="tlTotal"
        [usdTotal]="usdTotal"
      ></app-delivery-fee>
      <br />
    </strong>
  </li>
  <li class="list-group-item d-flex justify-content-between">
    <span> Toplam </span>
    <strong>
      <app-convert-total-price
        [user]="user"
        [TotalCartPrice]=""
        [tlTotal]="tlTotal"
        [usdTotal]="usdTotal"
      ></app-convert-total-price>
    </strong>
  </li>
  <!--  <li class="list-group-item d-flex justify-content-between bg-light">
      <div class="text-success">
                   <h6 class="my-0">Promo code</h6>
        <small>KDV</small>
      </div>
             <span class="text-success">-$5</span>
    </li>-->
</ul>
<div
  class="d-flex mt-1 justify-content-end"
  *ngIf="
    !this.user ||
    !this.user.userType ||
    this.user?.userType === this.userType.customer
  "
>
  <span class="text-muted" style="font-size: 12px"> (* KDV Dahil) </span>
</div>
<ng-container *ngIf="products.length < 1">
  <ngx-skeleton-loader
    *ngFor="let x of [1, 2, 3, 4, 5]"
    animation="progress"
  ></ngx-skeleton-loader>
</ng-container>
