<!-- breadcrumb start -->
<div class="breadcrumb-section scaling">
  <div class="container">
    <div class="row">
      <div class="col-8">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/'">ANASAYFA</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ breadcrumb }}</li>
          </ol>
        </nav>
      </div>
      <div class="col-4">
        <div class="page-title justify-content-end">
          <ng-container *ngIf="title === 'goBack'; else showTitle">
            <div class="btn btn-clear" (click)="goBack()"><i class="fa fa-long-arrow-left"></i> Geri</div>
          </ng-container>
          <ng-template #showTitle>
            <h2>{{ title }}</h2>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->
