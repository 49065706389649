import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../classes/product";
import * as _ from "lodash";
import { isEmpty } from "lodash";
import { PC_COMPONENTS } from "../../../enums/custom-build";
import { ViewportScroller } from "@angular/common";

const SOCKET_SPEC = "SOKET TİPİ";
const RAM_SPEC = "BELLEK TÜRÜ";

@Component({
  selector: "app-product-modal",
  templateUrl: "./product-modal.component.html",
  styleUrls: ["./product-modal.component.scss"],
})
export class ProductModalComponent implements OnInit {
  private tags: any[] = [];
  private isInStock = true;
  private filterLevel: any;
  private gLeveler: any;
  private allSelectedFilters = new Map();
  private filtersSelected = new Map();

  constructor(
    public productService: ProductService,
    public activeModal: NgbActiveModal,
    private viewScroller: ViewportScroller
  ) {}

  private static get motherBoard() {
    return JSON.parse(localStorage.getItem(PC_COMPONENTS.motherBoard));
  }

  private static get CPU() {
    return JSON.parse(localStorage.getItem(PC_COMPONENTS.cpu));
  }

  @Input() component;
  public products: Product[] = [];
  public ImageSrc: string;

  public grid = "col-lg-3 col-md-4 col-sm-6 col-xs-6 col-12";
  public layoutView = "list-view";
  featureCategories: any[] = [];
  selectedFeatures: any[] = [];
  brands: any[] = [];
  features: any[] = [];
  allProducts: Product[] = [];
  finished: boolean;
  private addItemCount: 30;
  public mobileSidebar = false;
  sortBy: any;
  private sortType: string;
  private inStock = true;
  public selectedFilters = new Map();

  isLoading = true;
  // SortBy Filter
  showAOnlyAvailableStock = true;

  private getCompatibleProduct(
    check: boolean,
    compatibleField: { name: string; spec: string }
  ) {
    let component = this.component;
    if (
      this.component === PC_COMPONENTS.ssdSata ||
      this.component === PC_COMPONENTS.ssdNvm
    ) {
      component = "SSD";
    }
    const filters = {
      onlyStock: "true",
      filterType: "pc-toplama",
      component: component,
    };

    this.productService
      .getProductsByKatTwo(component, filters)
      .subscribe((products) => {
        if (check) {
          this.allProducts = products.products.filter((item) => {
            if (this.component === "BELLEK") {
              if (
                item.features &&
                item.featureTags.includes("KATAGORİ:MASAÜSTÜ BELLEK")
              ) {
                const comp = item.features.find(
                  (ft) =>
                    ft.name === compatibleField.name &&
                    ft.spec === compatibleField.spec
                );
                if (comp) {
                  return item;
                }
              }
            } else {
              if (item.features) {
                const comp = item.features.find(
                  (ft) =>
                    ft.name === compatibleField.name &&
                    ft.spec === compatibleField.spec
                );
                if (comp) {
                  return item;
                }
              }
            }
          });
        } else {
          switch (component) {
            case "HDD":
              this.allProducts = products.products.filter((item) => {
                if (
                  item.features &&
                  item.featureTags.includes("KATAGORİ:MASAÜSTÜ HDD")
                ) {
                  return item;
                }
              });
              break;
            case "SSD":
              if (this.component === PC_COMPONENTS.ssdNvm) {
                this.allProducts = products.products.filter((item) => {
                  if (
                    item.features &&
                    !item.featureTags.includes("BAĞLANTI BİRİMİ:SATA")
                  ) {
                    return item;
                  }
                });
              } else if (this.component === PC_COMPONENTS.ssdSata) {
                this.allProducts = products.products.filter((item) => {
                  if (
                    item.features &&
                    item.featureTags.includes("BAĞLANTI BİRİMİ:SATA")
                  ) {
                    return item;
                  }
                });
              }
              break;
            default:
              this.allProducts = products.products;
          }
        }
        if (this.allProducts.length > 0) {
          this.productService.getCategoryOrder(component).subscribe((order) => {
            let preferredOrder = [];
            if (order.result === "OK") {
              preferredOrder = order.data;
            }
            this.productService
              .getProductFeatures(this.allProducts, component, preferredOrder)
              .subscribe((data) => {
                if (
                  _.isEmpty(this.brands) ||
                  _.isEmpty(this.selectedFeatures)
                ) {
                  this.featureCategories = data;
                }
              });
          });
          /*  this.productService.getProductFeatures(this.allProducts, this.component).subscribe((data) => {
            this.featureCategories = data;
          });*/
          this.addItems();
          this.setAvailable(true);
        } else {
          // this.addItems();
          this.setAvailable(false);
        }
        this.isLoading = false;
      });
  }

  ngOnInit() {
    document.getElementById("body").style.overflow = "hidden";
    document.getElementById("body").style.height = "100vh";
    switch (this.component) {
      case PC_COMPONENTS.cpu:
        this.getComparableFeatures(
          ProductModalComponent.motherBoard,
          SOCKET_SPEC
        );
        break;
      case PC_COMPONENTS.motherBoard:
        this.getComparableFeatures(ProductModalComponent.CPU, SOCKET_SPEC);
        break;
      case PC_COMPONENTS.cooler:
        this.getComparableFeatures(null, SOCKET_SPEC);
        break;
      case PC_COMPONENTS.ram:
        this.getComparableFeatures(ProductModalComponent.motherBoard, RAM_SPEC);
        break;
      default:
        this.getComparableFeatures(null);
    }

    // this.viewScroller.scrollToAnchor('productsM'); // Anchore Link
  }

  private getBrandedProducts() {
    return this.allProducts.filter((byBrand) => {
      if (this.brands.length > 0) {
        return this.brands.includes(byBrand.MARKA);
      }
      return true;
    });
  }

  private getComparableFeatures(product: Product, specName?: string) {
    if (product) {
      const spec = product.features.find((item) => item.name === specName);
      this.getCompatibleProduct(true, spec);
    } else {
      this.getCompatibleProduct(false, { name: null, spec: null });
    }
  }

  private get aggregateFeatures() {
    return Object.fromEntries(this.allSelectedFilters);
  }

  private getFiltered() {
    const filterTypeTwo = this.aggregateFeatures;
    const applyFilter = (data, filter) =>
      data.filter((product) => {
        return Object.entries(filter).every(([filterKey, filterValues]) => {
          // @ts-ignore
          return filterValues.includes(product[filterKey]);
        });
      });

    let filtered_products = applyFilter(
      this.getBrandedProducts(),
      filterTypeTwo
    );
    this.sortArrange(filtered_products);
  }

  private sortArrange(products) {
    switch (this.sortType) {
      case "name":
        this.products = this.productService.sortProducts(
          products,
          this.sortBy,
          this.sortType
        );
        break;
      case "price":
        this.products = this.productService.sortProducts(
          products,
          this.sortBy,
          this.sortType
        );
        break;
      case "stock":
        this.products = this.productService.sortProducts(
          products,
          this.sortBy,
          this.sortType
        );
        break;
    }
    if (this.isInStock) {
      this.products = this.productService.showProductsInStock(products, true);
    }

    setTimeout(() => {
      // this.showLoading = false;
    }, 1000);
    this.finished = true;
    // this.stopScroll = true;
  }

  private initBrands() {
    // check if both feature and brand selected

    if (this.features.length > 0 && this.brands.length > 0) {
      this.getFiltered();
      return;
    }
    if (this.brands.length > 0) {
      // if no feature filter by brand alone
      const products = this.allProducts.filter((product) => {
        if (this.isInStock) {
          return this.brands.includes(product.MARKA) && +product.stock !== 0;
        }
        return this.brands.includes(product.MARKA);
      });
      this.initFeatures(products, this.component);
      this.products = products;
      this.finished = true;
      return;
    }
    if (this.brands.length === 0) {
      this.initFeatures(this.allProducts, this.component);

      this.products = this.productService.showProductsInStock(
        this.allProducts,
        true
      );
    }
  }

  private initFeatures(products, selectedSubCategory) {
    // this.initLoading(true);
    const KatTwo = selectedSubCategory.toLocaleUpperCase("tr-TR");

    this.productService.getCategoryOrder(KatTwo).subscribe((res) => {
      let preferredOrder = [];
      if (res.result === "OK") {
        preferredOrder = res.data;
      }
      this.productService
        .getProductFeatures(products, selectedSubCategory, preferredOrder)
        .subscribe((data) => {
          if (_.isEmpty(this.brands) || _.isEmpty(this.selectedFeatures)) {
            this.featureCategories = data;
          }
          // this.initLoading(false);
        });
    });
  }

  filterByBrand(selectedBrand: any) {
    const filters = [];
    const filteredBrands = this.brands;
    const filteredTags = this.tags;
    const { brands, tags } = this.productService.filterProductsByBrands({
      filters,
      selectedBrand,
      filteredBrands,
      filteredTags,
    });
    this.brands = brands;
    this.tags = tags;
    this.initBrands();
    // this.router
    /* this.brands = brands;
     this.tags = tags;
     this.initBrands();
     let brands = brand.brand ? brand.brand.split(',') : [];
     brands = _.uniq(brands);
     this.brands = brands; // = brands;

     // check if both feature and brand selected
     if (this.features.length > 0 && this.brands.length > 0) {
       console.log('check-both');
       // if feature was selected apply filter to both brand and feature
       let f = [];
       this.allProducts.forEach((i) => {
         if (+i.stock !== 0) {
           const c = this.productService.arrayContainsArray(i.tags, this.features);
           console.log(i.stock);
           if (c) {
             f.push(i);
           }
         }
       });
       f = f.filter((product) => this.brands.includes(product.MARKA));
       if (this.sortBy && this.sortType) {
         f = this.productService.sortProducts(f, this.sortBy, this.sortType);
       }
       // apply brand filter
       this.products = f;
       this.finished = true;
       return;
     }
     if (this.brands.length > 0) {
       console.log('check-brand');

       // if no feature filter by brand alone
       let products = this.allProducts.filter((product) => this.brands.includes(product.MARKA) && +product.stock !== 0);
       if (this.sortBy && this.sortType) {
         products = this.productService.sortProducts(products, this.sortBy, this.sortType);
       }
       this.products = products;
       this.finished = true;

       return;
     }
     if (this.brands.length === 0) {
       let allProducts = this.allProducts;
       if (this.sortBy && this.sortType) {
         allProducts = this.productService.sortProducts(allProducts, this.sortBy, this.sortType);
       }
       this.products = allProducts;
       return;
     }*/
    return;
  }

  private initSelectedFeatures() {
    if (this.filterLevel > 0) {
      const resultFeatures = _.groupBy(this.selectedFeatures, "key");
      let gLeveler = {};
      for (const key in resultFeatures) {
        if (resultFeatures[key]) {
          const leveler = resultFeatures[key].filter(
            (item) => item.filterLevel > 0
          );
          if (leveler.length > 0) {
            gLeveler = {
              num: leveler.length,
              levelName: key,
              lastAddedFilterName: leveler.find((l) => l.specs.lastAdded)
                ?.value,
            };
          }
        }
      }
      if (_.isEmpty(gLeveler)) {
        this.filterLevel = 0;
      }
      this.gLeveler = gLeveler;
    }
    this.getFiltered();
  }

  customFilter(features, level, name?: string) {
    if (features.added) {
      if (
        this.allSelectedFilters.size > 0 &&
        this.allSelectedFilters.has(name)
      ) {
        this.allSelectedFilters.set(name, [
          ...this.allSelectedFilters.get(name),
          features.val,
        ]);
      } else {
        this.allSelectedFilters.set(name, [features.val]);
      }
      this.filtersSelected.set(
        `${name}${features.val}`,
        `${name}@${features.val}`
      );
      this.tags.push(`${name}:${features.val}`);
    } else {
      this.allSelectedFilters.set(
        name,
        this.allSelectedFilters
          .get(name)
          .filter((n) => !n.includes(features.val))
      );
      if (this.allSelectedFilters.get(name).length === 0) {
        this.allSelectedFilters.delete(name);
      }
      this.tags = this.tags.filter((tag) => tag !== `${name}:${features.val}`);
    }

    const params = {
      features,
      level,
      selectedFeatures: this.selectedFeatures,
      selectedFilters: this.selectedFilters,
      tags: this.tags,
    };
    const { tags, selectedFeatures, selectedFilters, filterLevel } =
      this.productService.filterProductsByFeature(params);
    this.selectedFeatures = selectedFeatures;
    this.selectedFilters = selectedFilters;
    this.filterLevel = filterLevel;
    this.tags = tags;
    document
      .querySelector(".productsM")
      .scrollTo({ top: 0, behavior: "smooth" });

    if (!_.isEmpty(this.selectedFeatures)) {
      this.initSelectedFeatures();
    } else {
      this.resetAllSelectedFeatures();
    }
  }

  private resetAllSelectedFeatures() {
    let productsToFilter = [];
    productsToFilter = this.allProducts;
    // reset back to all products in the selected brand
    if (this.brands.length > 0) {
      productsToFilter = this.allProducts.filter((item) =>
        this.brands.includes(item.MARKA)
      );
    }
    this.products = productsToFilter;
    this.finished = true;
  }

  updateFilter(tags: any) {
    console.log(tags);
    return;
    const features = this.featureCategories.find(
      (item) => item.name === Object.keys(tags)[0]
    );
    if (features) {
      tags = {
        features: tags[features.name].split("!")[0] ?? "",
      };
    }

    this.features = tags.features ? tags.features.split(",") : [];

    let f = [];
    let brandFiltered = [];

    document
      .querySelector(".productsM")
      .scrollTo({ top: 0, behavior: "smooth" });
    if (this.brands.length > 0 && this.features.length > 0) {
      brandFiltered = this.allProducts.filter(
        (product) => this.brands.includes(product.MARKA) && +product.stock !== 0
      );

      brandFiltered.forEach((i) => {
        console.log(i.features);
        console.log(this.features);

        if (i.stock !== 0) {
          const c = this.productService.arrayContainsArray(
            i.tags,
            this.features
          );
          console.log(c);
          if (c) {
            f.push(i);
          }
        }
      });
      if (this.sortBy && this.sortType) {
        f = this.productService.sortProducts(f, this.sortBy, this.sortType);
      }
      this.products = f;
      this.finished = true;
      return;
    }

    if (this.features.length === 0 && this.brands.length > 0) {
      let prod = this.allProducts;

      prod = prod.filter(
        (product) => this.brands.includes(product.MARKA) && +product.stock !== 0
      );
      if (this.sortBy && this.sortType) {
        prod = this.productService.sortProducts(
          prod,
          this.sortBy,
          this.sortType
        );
      }
      this.products = prod;
      this.finished = true;
      return;
    }
    /*TODO: get features from db*/
    if (this.features.length > 0) {
      this.allProducts.forEach((i) => {
        if (+i.stock !== 0) {
          console.log(i);
          if (!isEmpty(i.featureTags)) {
            console.log(this.selectedFeatures);
          }
          const c = this.productService.arrayContainsArray(
            i.featureTags,
            this.features
          );
          if (c) {
            f.push(i);
          }
        }
      });
      if (this.sortBy && this.sortType) {
        f = this.productService.sortProducts(f, this.sortBy, this.sortType);
      }
      this.products = f;
      this.finished = true;
      return;
    }
    // nothing was selected
    this.finished = false;
    this.addItems();
  }

  private addItems() {
    if (this.brands.length > 0) {
      this.finished = true;
      return;
    }
    if (this.allProducts.length === this.products.length) {
      this.finished = true;
      return;
    }
    this.products = this.allProducts.slice(0, this.addItemCount);
    this.finished = this.allProducts.length === this.products.length;
  }

  toggleMobileSidebar() {
    this.mobileSidebar = !this.mobileSidebar;
  }

  setAvailable(value: boolean) {
    this.inStock = value;
    this.products = this.productService.showProductsInStock(
      this.allProducts,
      this.inStock
    );
  }

  sortByName(value) {
    this.sortBy = value ? value : "ascending";
    this.sortType = "name";
    this.products = this.productService.sortProducts(
      this.products,
      this.sortBy,
      this.sortType
    );

    /*   this.viewScroller.setOffset([120, 120]);
       this.viewScroller.scrollToAnchor('products'); // Anchore Link*/
  }

  sortByStock(value) {
    this.sortBy = value ? value : "ascending";
    this.sortType = "stock";
    this.products = this.productService.sortProducts(
      this.products,
      this.sortBy,
      this.sortType
    );
  }

  sortByPrice(value) {
    this.sortBy = value ? value : "ascending";
    this.sortType = "price";
    this.products = this.productService.sortProducts(
      this.products,
      this.sortBy,
      this.sortType
    );
  }

  updateGridLayout($event: any) {
    this.layoutView = "grid-view";
  }

  updateLayoutView(value: any) {
    this.layoutView = value;
  }

  selectProduct(product: Product) {
    product.quantity = 1;
    this.activeModal.close(product);
    document.getElementById("body").style.overflow = "inherit";
    document.getElementById("body").style.height = "auto";
  }
}
