<div class="navbar">
  <a href="javascript:void(0)" (click)="leftMenuToggle()">
    <div class="bar-style"><i class="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
  </a>
  <div id="mySidenav" class="sidenav mobile-menu-items" [class.openSide]="navService?.mobileMenuToggle">
    <a
      href="javascript:void(0)"
      class="sidebar-overlay"
      (click)="navService.mobileMenuToggle = false"
      (mouseover)="onHover(false)"
    ></a>
    <nav id="unset">
      <div (click)="leftMenuToggle()">
        <div class="sidebar-back text-left"><i class="fa fa-angle-left pr-2" aria-hidden="true"></i> Geri</div>
      </div>
      <ul id="sub-menu" class="sidebar-menu">
        <li *ngFor="let menuItem of menuItems" (mouseover)="onHover(!!menuItem.children)">
          <!-- Sub -->
          <a href="javascript:void(0)" *ngIf="menuItem.type === 'sub'" (click)="toggletNavActive(menuItem)">
            {{ menuItem.title }}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- Link -->
          <a [routerLink]="menuItem.path" *ngIf="menuItem.type === 'link'">
            {{ menuItem.title }}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Link -->
          <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extLink'">
            {{ menuItem.title }}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- External Tab Link -->
          <a href="{{ menuItem.path }}" *ngIf="menuItem.type === 'extTabLink'">
            {{ menuItem.title }}
            <span class="sub-arrow" *ngIf="menuItem.children"></span>
          </a>
          <!-- 2nd Level Menu -->
          <ul
            [class]="menuItem.megaMenu ? 'mega-menu clothing-menu' : ''"
            [class.opensub1]="menuItem.active"
            *ngIf="menuItem.children"
          >
            <!-- Simple Menu Start-->
            <ng-container *ngIf="!menuItem.megaMenu">
              <li *ngFor="let childrenItem of menuItem.children">
                <!-- Link -->
                <a
                  class="has-submenu"
                  [routerLink]="childrenItem.path"
                  [queryParams]="{
                    category: childrenItem.title,
                    og: menuItem.title
                  }"
                  *ngIf="childrenItem.type === 'sub'"
                >
                  {{ childrenItem.title }}
                </a>
              </li>
            </ng-container>
            <!-- Simple Menu End-->
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</div>
