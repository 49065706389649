import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "./api.service";
import { Product } from "../classes/product";
import { LocalforageService } from "./localforage";
import { catchError, first, map, shareReplay, take } from "rxjs/operators";
import { ErrorHandlerService } from "./error-handler.service";
import { environment } from "../../../../environments/environment";

const state = {
  products: JSON.parse(localStorage.products || "[]"),
};
const TIME_DIFFERENCE = 1; // 1 HR

const CACHE_SIZE = 1;

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public Currency = { name: "Dollar", currency: "USD", price: 1 }; // Default Currency
  public Products;
  private categorySource = new Subject<any>();
  categoryAnnounced$ = this.categorySource.asObservable();
  private productsCache$: Observable<Product[]>;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private localForage: LocalforageService,
    private toastService: ToastrService,
    private errorHandler: ErrorHandlerService
  ) {}

  // Get Products
  public get getProducts() {
    if (!this.productsCache$) {
      this.productsCache$ = this.products.pipe(shareReplay(CACHE_SIZE));
    }
    return this.productsCache$;
  }

  get brand() {
    const uniqueBrands = [];
    state.products.filter((product) => {
      if (product.MARKA) {
        const index = uniqueBrands.indexOf(product.MARKA);
        if (index === -1) {
          uniqueBrands.push(product.MARKA);
        }
      }
    });
    return uniqueBrands;
  }

  private get products(): Observable<Product[]> {
    this.Products = this.apiService
      .post(`${environment.apiUrl}/products`, {
        method: "getProducts",
        route: "admin",
        data: {
          page: 100,
        },
      })
      .pipe(
        first(),
        map((response) => {
          console.count("products");
          if (response && response.result === "OK") {
            response.data.map((product) => {
              return (product = this.transformProduct(product));
            });
            // this.apiService.broadCastProducts(response.data);
            return response.data;
          } else {
            return [];
          }
        })
      );
    return this.Products;
  }

  public deleteFromFeaturedProducts(stokNo: string) {
    return this.apiService
      .post(`${environment.apiUrl}/products`, {
        method: "deleteFromFeaturedProducts",
        data: { stokNo },
        route: "admin",
      })
      .pipe(catchError(this.errorHandler.handleError));
  }

  /*
      ---------------------------------------------
      ---------------  Product  -------------------
      ---------------------------------------------
    */

  public getFeaturedNewProducts(section: string) {
    const data = {
      section,
    };
    return this.apiService
      .post(`${environment.apiUrl}/products`, {
        method: "getFeaturedNewProducts",
        data: {
          section: data,
          user: null,
        },
        route: "product",
      })
      .pipe(catchError(this.errorHandler.handleError));
  }

  addFeaturedNewProducts(products, section: string) {
    const data = {
      products,
      section,
    };
    return this.apiService
      .post(`${environment.apiUrl}/products`, {
        method: "addFeaturedNewProducts",
        route: "admin",
        data,
      })
      .pipe(catchError(this.errorHandler.handleError));
  }

  public getProductCategory(
    category: string,
    useKatTwo: boolean
  ): Observable<Product[]> {
    return this.apiService
      .post(`${environment.apiUrl}/products/categories`, {
        method: "getProductsByCategory",
        route: "product",
        data: { user: null, useKatTwo },
        category, // .replace(this.apiService.regEx, ' ')
      })
      .pipe(
        take(1),
        map((res) => {
          res.data.map((product) => {
            if (product.STOKNO) {
              return (product = this.transformProduct(product));
            }
          });
          // for order components
          return res.data;
        })
      );
  }

  public extractFeatures(products: Product[]) {
    return [];
  }

  /*
      ---------------------------------------------
      -------------  Compare Product  -------------
      ---------------------------------------------
    */

  // Calculate Stock Counts

  /* @args items as cart Items quantity as number of products to be added*/
  public calculateStockCounts(cartItems, quantity, product: Product) {
    // new product added
    // check if total quantity exceeds quantity added and quantity to be added
    // const qty = product.quantity + quantity;
    const stock = product.stock;
    if (stock < quantity || stock === 0) {
      this.toastService.error(
        "You can not add more items than available. In stock " +
          stock +
          " items."
      );
      return false;
    }
    return true;
  }

  /*
      ---------------------------------------------
      ------------  Filter Product  ---------------
      ---------------------------------------------
    */

  public filterProductsByBrand(brand: string): Observable<Product[]> {
    const products = state.products.filter((item: Product) => {
      return item.MARKA === brand;
    });
    return of(products);
    // return this.products.pipe(map(product => {
    //         return product.filter((item: Product) => {
    //             return item.MARKA === brand;
    //         });
    //     }
    // ));
  }

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    const products = state.products.filter((item: Product) => {
      if (!filter.length) {
        return true;
      }
      const Tags = filter.some((prev) => {
        // Match Tags
        if (item.tags) {
          if (item.tags.includes(prev)) {
            return prev;
          }
        }
      });
      return Tags;
    });
    return of(products);
    /* return this.products.pipe(map(product =>
             product.filter((item: Product) => {
                 if (!filter.length) {
                     return true;
                 }
                 const Tags = filter.some((prev) => { // Match Tags
                     if (item.tags) {
                         if (item.tags.includes(prev)) {
                             return prev;
                         }
                     }
                 });
                 return Tags;
             })
         ));*/
  }

  public showProductsInStock(products: Product[], payload: boolean): any {
    if (payload) {
      return products.filter((item) => {
        return item.stock > 0;
      });
    }
    return products;
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {
    if (payload === "ascending") {
      return products.sort((a, b) => {
        if (a.STOKNO < b.STOKNO) {
          return -1;
        } else if (a.STOKNO > b.STOKNO) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "a-z") {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "z-a") {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "low") {
      return products.sort((a, b) => {
        if (a.SATISFIYATI4 < b.SATISFIYATI4) {
          return -1;
        } else if (a.SATISFIYATI4 > b.SATISFIYATI4) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "high") {
      return products.sort((a, b) => {
        if (a.SATISFIYATI4 > b.SATISFIYATI4) {
          return -1;
        } else if (a.SATISFIYATI4 < b.SATISFIYATI4) {
          return 1;
        }
        return 0;
      });
    }
  }

  /*
      ---------------------------------------------
      ------------- Product Pagination  -----------
      ---------------------------------------------
    */
  public getPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 16
  ) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    const paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    // eslint-disable-next-line one-var
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  }

  updateProduct(value: any, productId: string) {
    return this.apiService.set(`products/${productId}`, value);
  }

  addTechnicalDetails(stockNo: string, technicalDetails: string) {
    return new Promise((resolve, reject) => {
      this.apiService
        .post(`${environment.apiUrl}/products`, {
          product: { stockNo, technicalDetails },
          method: "addTechnicalDetails",
          route: "admin",
        })
        .subscribe((resp: any) => {
          if (resp.result === "OK") {
            return resolve(true);
          }
          return reject({
            state: false,
            reason: resp.message,
          });
        });
    });
  }

  addProductInformation(stockNo, information) {
    return new Promise((resolve, reject) => {
      this.apiService
        .post(`${environment.apiUrl}/products`, {
          product: { stockNo, information },
          method: "addProductInformation",
          route: "admin",
        })
        .subscribe((resp: any) => {
          if (resp.result === "OK") {
            return resolve(true);
          }
          return reject({
            state: false,
            reason: resp.message,
          });
        });
    });
  }

  updateProductImage(stockNo: string, images: string) {
    return new Promise((resolve, reject) => {
      this.apiService
        .post(`${environment.apiUrl}/products`, {
          product: { stockNo, images },
          method: "addPhotos",
          route: "admin",
        })
        .subscribe((resp: any) => {
          if (resp.result === "OK") {
            resolve(true);
          }
          reject(false);
        });
    });
  }

  getProductByStockNumber(stockNumber: string) {
    return this.apiService.post(`products`, {
      method: "getProductByCode",
      data: { code: stockNumber },
      route: "product",
    });
  }

  // Product
  /* 	private products(): Observable<Product[]> {
        /* const localStore: any = await this.localForage.get('productds');
        const currentTime = Date.now().toLocaleString();
        if (localStore) {
            const dateSaved = new Date(localStore.savedAt).toLocaleDateString();
            const timeSaved = new Date(localStore.savedAt).toLocaleTimeString();
            // const currentTime = Date.now();
            return (this.Products = Promise.resolve(of(localStore.products)));
        } */

  // return this.fetchProducts();
  // }

  /* 	private fetchProducts() {
        if (this.Products) return this.Products;
        this.Products = this.apiService
            .post(`${environment.apiUrl}/products`, {
                method: 'getAllProducts',
                route: 'admin',

                data: {
                    page: 100,
                },
            })
            .pipe(
                take(1),
                map((data: any) => {
                    if (data.result == 'OK') {
                        data.data.map(async (nx) => {
                            if (!nx.images) {
                                nx.images = [
                                    {
                                        src: 'assets/images/placeholder.jpg',
                                    },
                                ];
                            } else if (nx.images == '[]') {
                                nx.images = [
                                    {
                                        src: 'assets/images/placeholder.jpg',
                                    },
                                ];
                            } else {
                                nx.images = JSON.parse(nx.images);
                            }
                            nx.stock = +(nx.MBAKIYE + nx.LBAKIYE);
                            nx.tags = [nx.MARKA];
                            nx.title = nx.WEBACIKLAMA;
                            if (!nx.TEKNIK) nx.TEKNIK = '';
                            if (!nx.BELGISI) nx.BELGISI = '';
                        });
                        this.localForage.set('products', {
                            products: data.data,
                            savedAt: Date.now(),
                        });
                        return data.data;
                    }
                    return [];
                })
            );

        return this.Products;
    } */

  private transformProduct(product: Product) {
    if (!product.images) {
      product.images = [
        {
          src: "assets/images/placeholder.jpg",
        },
      ];
    } else if (JSON.stringify(product.images) === "[]") {
      product.images = [
        {
          src: "assets/images/placeholder.jpg",
        },
      ];
    } else {
      const img = product.images as unknown as string;
      product.images = JSON.parse(img);
    }
    if (!product.BILGISI) {
      product.BILGISI = "";
    }
    if (!product.TEKNIK) {
      product.TEKNIK = "";
    }

    // eslint-disable-next-line radix
    product.stock = parseInt(product.MBAKIYE) + parseInt(product.LBAKIYE);
    product.title = product.WEBACIKLAMA;

    // eslint-disable-next-line radix
    product.SATISFIYATI3 = parseInt(product.SATISFIYATI3);
    // eslint-disable-next-line radix
    product.SATISFIYATI2 = parseInt(product.SATISFIYATI2);
    // eslint-disable-next-line radix
    product.SATISFIYATI1 = parseInt(product.SATISFIYATI1);

    return product;
  }

  saveCategoryOrder(categoryOrder: any[], categoryTitleSlug: string) {
    return this.apiService.post(`products`, {
      method: "saveCategoryOrder",
      route: "product",
      data: {
        categoryOrder,
        categoryTitleSlug: categoryTitleSlug
          .toLocaleUpperCase("tr-TR")
          .replace(/\s+/g, "_"),
      },
    });
  }

  // getCategoryOrder(katTwoSlug: string) {
  //   return this.apiService.post(`products`, {
  //     method: 'getCategoryOrder',
  //     route: 'product',
  //     data: { categoryTitleSlug: katTwoSlug },
  //   });
  // }
  updateCategory(currentUser) {
    return this.apiService.post(`products`, {
      method: "updateProductCategories",
      route: "product",
      data: {
        updateCategory: true,
        user: currentUser,
      },
    });
  }
}
