import { User } from "../../shared/classes/user";
import {
  LogInErrorReset,
  LogInFailure,
  LogInSuccess,
  LogOut,
  UserListener,
} from "../actions/auth.action";
import { createReducer, on } from "@ngrx/store";

export interface State {
  // is a user authenticated?
  isAuthenticated: boolean;
  // if authenticated, there should be a user object
  user: User | null;

  // error message
  errorMessage: any | null;
  errorCode: string | null;
  extraData?: any | null;
}

export const initialState: State = {
  isAuthenticated: false,
  user: null,
  errorMessage: null,
  errorCode: null,
  extraData: null,
};

export const reducer = createReducer(
  initialState,
  on(LogInSuccess, (state, action) => ({
    ...state,
    user: {
      ...action.payload,
    },
    isAuthenticated: true,
    errorMessage: null,
    pending: false,
  })),

  on(UserListener, (state, action) => {
    // console.log(state);
    return {
      ...state,
    };
  }),

  on(LogInFailure, (state, { error }) => ({
    ...state,
    errorMessage: error?.message || error,
    errorCode: error?.errorCode,
    extraData: error?.rData ?? null,
    isAuthenticated: false,
    user: null,
    pending: false,
  })),
  on(LogOut, () => initialState),
  on(LogInErrorReset, () => initialState)
);

// export const getError = (state: State) => state.errorMessage;
export const getUser = (state: State) => state;

/*
*
* switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        user: {
          ...action.payload,
        },
        errorMessage: null,
      };
    }
    case AuthActionTypes.LOGIN_FAILURE: {
      console.log(state);
      return {
        ...state,
        errorMessage: "Incorrect email and/or password.",
      };
    }
    default: {
      return state;
    }
  }*/
