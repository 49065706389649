export enum UserType {
  retailer = "retailer",
  customer = "customer",
  guest = "guest",
}

export class User {
  [x: string]: any;

  id?: string;
  username?: string;
  password: string;
  firstName: string;
  lastName: string;
  token?: string;
  email: string;
  phoneNumber?: string;
  userType: UserType;
  // address?: any
  address?: any;
  companyName?: string;
  approved?: boolean;
  priceCategory: string;
}
