<app-header></app-header>
<app-breadcrumb [title]="''" [breadcrumb]="''"></app-breadcrumb>
<section class="about-page team section-b-space">
  <div class="container">
    <div class="row">
      <div class="col-sm-12" *ngIf="content | async as data; else noContent">
        <h2>{{ data.title }}</h2>
        <div [froalaView]="data?.content"></div>
      </div>

      <ng-template #noContent>
        <div class="col-sm-12">
          <div class="text-center">No Content</div>
        </div>
      </ng-template>
    </div>
  </div>
</section>

<app-footer-two></app-footer-two>
