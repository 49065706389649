import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paynet-redirect-response',
  templateUrl: './paynet-redirect-response.component.html',
  styleUrls: ['./paynet-redirect-response.component.scss']
})
export class PaynetRedirectResponseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
