import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
} from "@angular/core";
import { Product } from "../../classes/product";
import { ProductModalComponent } from "../modal/product/product-modal.component";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PC_COMPONENTS } from "../../enums/custom-build";
import { ProductService } from "../../services/product.service";
import { PcBuilderService } from "../../services";
import { NAME_REGEX } from "../../interfaces/interface";

@Component({
  selector: "app-selected-item",
  templateUrl: "./selected-item.component.html",
  styleUrls: ["./selected-item.component.scss"],
})
export class SelectedItemComponent implements OnInit, OnChanges {
  product: Product;
  @Input() componentName: string;
  @Input() prodRemove: boolean;
  // @Output() selectedComponent: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatePcBuilder: EventEmitter<any> = new EventEmitter<any>();
  currency: any = this.productService.Currency;
  pcComponents = PC_COMPONENTS;
  selectedCPU: Product;
  public ImageSrc: string;
  quantity = 1;
  isPSU: boolean;
  maxRam = 4;
  isOptic = false;
  private closeResult: string;
  isQuantitySelect = false;
  focusMe = false;
  maxMotherBoardMaxRamSizeWarning: string;

  constructor(
    private modalService: NgbModal,
    public productService: ProductService,
    private pcBuilderService: PcBuilderService
  ) {}

  ngOnInit() {
    const product = this.pcBuilderService.getPcBuildComponent(
      this.componentName
    );
    if (product) {
      this.product = product;
      this.idComponents(product);
    }
  }

  getQuantity() {
    if (this.componentName === this.pcComponents.ram) {
      const slots = this.geMaxSlots();
      return this.convertNumberToArray(slots);
    }
    return this.convertNumberToArray(5);
  }

  private convertNumberToArray = (num: number): Array<number> =>
    Array.from({ length: num }, (_, i) => i + 1);

  private geMaxSlots() {
    const motherBoard = this.pcBuilderService.getPcBuildComponent(
      this.pcComponents.motherBoard
    );
    if (!motherBoard) {
      return 5;
    }
    const maxSlot: { name: string; spec: string } | null = this.getMaxRamSlot(
      motherBoard.features
    ); // get max ram slot
    let slots;
    if (maxSlot) {
      slots = +maxSlot.spec;
    }
    return slots ? slots : 4;
  }

  private computeMaxAllowedRam() {
    this.maxRam = this.geMaxSlots();
    const motherBoard = this.pcBuilderService.getPcBuildComponent(
      this.pcComponents.motherBoard
    );
    const ram = this.pcBuilderService.getPcBuildComponent(
      this.pcComponents.ram
    );
    const maxAllowedRam = this.getMaxRamAllowed(motherBoard.features);

    const userSelectedRam = ram.features.find(
      (item) => item.name === "BELLEK KAPASİTE"
    );
    const selectedRamSize = userSelectedRam.spec.replace(/\D+$/g, "");
    const maxMotherBoardMaxRamSize = maxAllowedRam.spec.replace(/\D+$/g, "");

    const totalRamSizeSelected = selectedRamSize * this.quantity;

    if (totalRamSizeSelected > maxMotherBoardMaxRamSize) {
      // show warning
      this.maxMotherBoardMaxRamSizeWarning =
        "Max allow ram is: " +
        maxMotherBoardMaxRamSize +
        " Your selection: " +
        totalRamSizeSelected;
    }
  }

  private idComponents(product: Product) {
    switch (this.componentName) {
      case PC_COMPONENTS.motherBoard:
        this.maxRam = this.geMaxSlots();
        break;
      case PC_COMPONENTS.case:
        this.isPSU = this.checkForPSU(product.features);
        // const hasDvd = this.checkForDvdDrive(product.features);
        break;
      case PC_COMPONENTS.ram:
        this.isQuantitySelect = true;
        console.clear();
        this.quantity = +product.quantity || 1;
        this.computeMaxAllowedRam();

        break;
      case PC_COMPONENTS.ssdSata:
        this.quantity = +product.quantity || 1;
        this.isQuantitySelect = true;
        break;
      case PC_COMPONENTS.hdd:
        this.quantity = +product.quantity || 1;
        this.isQuantitySelect = true;
        break;
      case PC_COMPONENTS.dvdDrive:
        const pcCase = this.pcBuilderService.getPcBuildComponent(
          this.pcComponents.case
        );
        if (pcCase) {
          const dvd: { name: string; spec: string } | null =
            this.checkForDvdDrive(pcCase.features);
          let isDvd;
          if (dvd) {
            isDvd = dvd.spec !== "YOK";
          }
        }

        break;
    }
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    // Extract changes to the input property by its name
    const change: SimpleChange = changes.prodRemove;
    if (change.currentValue) {
      this.product = null;
    }
    // Whenever the data in the parent changes, this method gets triggered. You
    // can act on the changes here. You will have both the previous value and the
    // current value here.
  }

  selectComponent(component: string) {
    if (
      component === this.pcComponents.ram &&
      !this.pcBuilderService.getPcBuildComponent(this.pcComponents.motherBoard)
    ) {
      alert("Lütfen bir anakart seçin");
      return;
    }
    if (
      component === this.pcComponents.dvdDrive &&
      this.pcBuilderService.getPcBuildComponent(this.pcComponents.case)
    ) {
      if (
        this.pcBuilderService
          .getPcBuildComponent(this.pcComponents.case)
          .featureTags.includes("OPTİK SÜRÜCÜ:YOK")
      ) {
        alert("OPTİK SÜRÜCÜ YOK");
        return;
      }
    }

    this.openProductModal(component);
  }

  removeSelected(product: Product, componentName: string) {
    this.pcBuilderService.removeBuildComponent(componentName);
    this.product = null;
    this.updatePcBuilder.emit({ action: "delete", product });
  }

  updateQuantity(event, product: Product) {
    this.product.quantity = +this.quantity;
    this.updatePcBuilder.emit({ action: "quantityUpdate", product });
    this.pcBuilderService.setBuildComponent = {
      componentName: this.componentName,
      component: product,
    };
  }

  private getMaxRamSlot = (features: any[]) =>
    features.find((item) => item.name === "BELLEK SLOT SAYISI");

  private getMaxRamAllowed = (features: any[]) =>
    features.find((item) => item.name === "MAX BELLEK DESTEĞİ");

  private checkForPSU = (features: any[]) =>
    features.some((item) => item.spec === "PSU YOK");

  private checkForDvdDrive = (features: any[]) =>
    features.find((item) => item.name === "OPTİK SÜRÜCÜ");

  private openProductModal(component: string) {
    const modalRef = this.modalService.open(ProductModalComponent, {
      windowClass: "productModalClass",
      centered: false,
      scrollable: true,
    });
    modalRef.componentInstance.component = component;
    modalRef.result.then(
      (product) => {
        if (product) {
          this.pcBuilderService.setBuildComponent = {
            componentName: component,
            component: product,
          };
          this.product = product;
          this.updatePcBuilder.emit({ action: "add", product });

          this.idComponents(product);
        }
        document.getElementById("body").style.overflow = "unset";
        document.getElementById("body").style.height = "auto";
        this.closeResult = `Closed with: ${product}`;
        setTimeout(() => {
          this.focusMe = false;
        }, 2000);
      },
      (reason) => {
        document.getElementById("body").style.overflow = "unset";
        document.getElementById("body").style.height = "auto";
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        setTimeout(() => {
          this.focusMe = false;
        }, 2000);
        console.log("focus");
      }
    );

    this.focusMe = true;
  }

  private getDismissReason = (reason: any): string => {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  };

  stripName(name: string) {
    return name.replace(NAME_REGEX, "");
  }
}
