import { Component, OnInit } from '@angular/core';
import { Menu, NavService } from '../../services/nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  public menuItems: Menu[];

  constructor(public navService: NavService, private router: Router) {
    this.navService.getNewMenu.subscribe((menu) => {
      this.menuItems = menu; // menu.sort((a, b) => a.title.localeCompare(b.title));
      console.log(menu);
    });
    this.router.events.subscribe((event) => {
      this.navService.mobileMenuToggle = false;
    });
  }

  ngOnInit(): void {}

  leftMenuToggle(): void {
    this.navService.mobileMenuToggle = !this.navService.mobileMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  onHover(menuItem) {
    if (window.innerWidth > 1200 && menuItem) {
      document.getElementById('unset').classList.add('sidebar-unset');
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset');
    }
  }
}
