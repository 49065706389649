import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';
import { QuickViewComponent } from '../../modal/quick-view/quick-view.component';
import { CartModalComponent } from '../../modal/cart-modal/cart-modal.component';
import { Product } from '../../../classes/product';
import { ProductService } from '../../../services/product.service';

@Component({
  selector: 'app-product-box-four',
  templateUrl: './product-box-four.component.html',
  styleUrls: ['./product-box-four.component.scss']
})
export class ProductBoxFourComponent implements OnInit {

  @Output() selectComponent: EventEmitter<Product> = new EventEmitter<Product>();
  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() onHowerChangeImage = false; // Default False
  @Input() cartModal = false; // Default False
  @Input() loader = false;
  @ViewChild('quickView') QuickView: QuickViewComponent;
  @ViewChild('cartModal') CartModal: CartModalComponent;

  public ImageSrc: string;

  constructor(private productService: ProductService) { }

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => {
        this.loader = false;
      }, 2000); // Skeleton Loader
    }
  }

  selectProduct(product: Product){
    this.selectComponent.emit(product);
  }


}
