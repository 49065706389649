import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import "rxjs/add/observable/of";
import "rxjs/add/operator/map";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/catch";
import { map, switchMap, tap } from "rxjs/operators";
import { AuthService } from "../../shared/services/auth.service";
import {
  AuthActionTypes,
  LogInFailure,
  LoginRedirect,
  LogInSuccess,
  UserListener,
  UserListenerSuccess,
} from "../actions/auth.action";
import { LocalstorageService } from "../../shared/services/localstorage.service";
import { ToastService } from "../../shared/services/toast.service";
import { of } from "rxjs";
import { CartService } from "../../shared/services/cart.service";
import { isEmpty } from "lodash";
import { initialState } from "../reducers/auth.reducer";

@Injectable()
export class AuthEffects {
  LogIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActionTypes.LOGIN),
      map((action) => action),
      switchMap((value: any) => {
        return this.authService
          .logIn(value)
          .map((user) => {
            if ("password" in user) {
              delete user.password;
            }
            this.cartService.deleteCart();
            return LogInSuccess({ payload: user, redirect: value.redirect });
          })
          .catch((error) => {
            return of(LogInFailure({ error }));
          });
      })
    );
  });

  FetchUserData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActionTypes.USER_LISTENER),
      map((action) => action),
      switchMap((value: any) => {
        return this.authService
          .ObserveUserData(value)
          .map((user) => {
            if (!isEmpty(user)) {
              return UserListenerSuccess({ payload: user });
            }
            return UserListenerSuccess({ payload: initialState });
          })
          .catch((error) => {
            return of(UserListenerSuccess({ payload: initialState }));
          });
      })
    );
  });

  UserListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.USER_LISTENER_SUCCESS),
        tap((result: any) => {
          // this.toastService.successToast("Login successful");
        })
      ),
    { dispatch: false }
  );

  LoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LOGIN_SUCCESS),
        tap((result: any) => {
          this.storage.setObject("token", result.payload.token);
          this.toastService.successToast("Login successful");
          this.router.navigate([result.redirect]).then();
        })
      ),
    { dispatch: false }
  );
  LoginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LOGIN_REDIRECTS, AuthActionTypes.LOGOUT),
        tap((authed) => {
          this.router.navigate(["/login"]).then((r) => {});
        })
      ),
    { dispatch: false }
  );
  LogInFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LOGIN_FAILURE),
        tap((result: any) => {
          console.log(result);
          if ("error" in result) {
            const message = result?.error?.message ?? result.error;
            this.toastService.errorToast(message);
          }
        })
      ),
    { dispatch: false }
  );
  LogOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LOGOUT),
        map((result) => {
          this.storage.removeItem("token");
          this.cartService.deleteCart();
          this.toastService.successToast("logged out");
          return LoginRedirect();
        })
      ),
    { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private storage: LocalstorageService,
    private router: Router,
    private toastService: ToastService,
    private cartService: CartService
  ) {}
}
