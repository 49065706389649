<ng-container *showItBootstrap="['xl', 'lg']">
  <app-header [sticky]="true"></app-header>
</ng-container>
<ng-container *showItBootstrap="['md', 'sm', 'xs']">
  <app-mobile-header [sticky]="true" [class]=""></app-mobile-header>
</ng-container>
<div class="overlay" *ngIf="loading"></div>
<app-breadcrumb [title]="pageTitle" [breadcrumb]=""></app-breadcrumb>
<!-- section start -->
<section class="login-page section-b-space">
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="col-lg-6" *ngIf="showRetailerForm">
        <div
          class="d-flex justify-content-between asbil-items-baseline asbil-mb-[1rem]"
        >
          <h3>KAYITLI BAYİ GİRİŞİ</h3>
          <div class="">
            <a
              class="asbil-btn asbil-btn-sm asbil-btn-outline asbil-btn-primary sign-up"
              routerLink="/auth/bayi-ol"
            >
              Bayi Ol</a
            >
          </div>
        </div>
        <form class="needs-validation was-validated" [formGroup]="retailerForm">
          <div class="theme-card authentication-right asbil-card">
            <div class="asbil-card-body">
              <h6 class="title-font">
                Daha önceden oluşturulmuş hesabınız varsa Müşteri Kodunuz ve
                şifrenizi giriniz.
              </h6>
              <div *ngIf="errorMessage">
                <ngb-alert [type]="'danger'" (close)="close(null)">{{
                  errorMessage
                }}</ngb-alert>
              </div>
              <div class="asbil-form-control asbil-w-full">
                <label class="asbil-label">
                  <span class="asbil-label-text">Müşteri Kodunuz</span>
                  <!--                <span class="asbil-label-text-alt">*</span>-->
                </label>
                <input
                  type="text"
                  id="retailerCode"
                  placeholder=""
                  required
                  formControlName="retailerCode"
                  class="asbil-input asbil-input-bordered asbil-w-full"
                />
              </div>
              <div class="asbil-form-control asbil-w-full">
                <label class="asbil-label">
                  <span class="asbil-label-text">Şifre</span>
                  <!--                <span class="asbil-label-text-alt">Alt label</span>-->
                </label>
                <input
                  type="password"
                  id="loginPassword"
                  placeholder=""
                  required
                  formControlName="password"
                  class="asbil-input asbil-input-bordered asbil-w-full"
                />
                <!-- <label class="asbil-label">
                   <span class="asbil-label-text-alt">Alt label</span>
               <span class="asbil-label-text-alt">Alt label</span>
                 </label>-->
              </div>

              <div
                class="asbil-card-actions asbil-justify-between asbil-items-center asbil-mt-[1.2rem]"
              >
                <div class="forgort-pass">
                  <a class="btn btn-link" routerLink="/auth/sifre-un/bayi"
                    >Şifremi Unuttum?</a
                  >
                </div>
                <div class="">
                  <button
                    class="asbil-btn asbil-w-[12rem] asbil-btn-primary"
                    (click)="loginRetailer()"
                  >
                    Giriş Yap
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="col-lg-6" *ngIf="showConsumerForm">
        <div
          class="asbil-flex asbil-justify-between asbil-items-center asbil-mb-[1rem]"
        >
          <div class="asbil-text-[1.1rem]">Üye Girişi</div>
          <button
            class="asbil-btn asbil-btn-sm asbil-btn-outline asbil-btn-primary sign-up"
            routerLink="/auth/uye-ol"
          >
            Üye Ol
          </button>
        </div>
        <div class="theme-card authentication-right asbil-card">
          <div *ngIf="errorMessage">
            <ngb-alert [type]="'danger'" (close)="close(null)">{{
              errorMessage
            }}</ngb-alert>
          </div>
          <form
            class="needs-validation was-validated"
            [formGroup]="consumerForm"
          >
            <div class="asbil-form-control w-full">
              <label class="asbil-label">
                <span class="asbil-label-text">E-Posta</span>
                <!--                <span class="asbil-label-text-alt">*</span>-->
              </label>
              <input
                type="email"
                id="email"
                placeholder=""
                required
                formControlName="email"
                class="asbil-input asbil-input-bordered w-full"
              />
              <!-- <label class="asbil-label">
                 <span class="asbil-label-text-alt">Alt label</span>
             <span class="asbil-label-text-alt">Alt label</span>
               </label>-->
            </div>
            <div class="asbil-form-control asbil-w-full">
              <label class="asbil-label">
                <span class="asbil-label-text">Şifre</span>
                <!--                <span class="asbil-label-text-alt">*</span>-->
              </label>
              <input
                type="password"
                id="password"
                placeholder=""
                required
                formControlName="password"
                class="asbil-input asbil-input-bordered asbil-w-full"
              />
              <!-- <label class="asbil-label">
                 <span class="asbil-label-text-alt">Alt label</span>
             <span class="asbil-label-text-alt">Alt label</span>
               </label>-->
            </div>

            <div
              class="asbil-card-actions asbil-justify-between asbil-items-center mt-3"
            >
              <div class="forgort-pass">
                <a
                  class="asbil-link asbil-link-hover"
                  routerLink="/auth/sifre-un"
                  >Şifremi Unuttum?</a
                >
              </div>
              <div class="login-btn-wrapper">
                <button
                  class="asbil-btn asbil-btn-primary asbil-w-[100px]"
                  (click)="loginConsumer()"
                >
                  Giriş Yap
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Section ends-->
<app-footer-two></app-footer-two>
