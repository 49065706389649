<app-header></app-header>

<app-breadcrumb [title]="''" [breadcrumb]="'HAKKIMIZDA'"></app-breadcrumb>
<!-- about section start -->
<section class="about-page team">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h2>HAKKIMIZDA</h2>
        <p>
          Firmamız 2015 Yılında , sektördeki 20 yıllık tecrübe ve deneyimi ile
          yenilikçi ve gelişmiş b2b web sitesi özellikleriyle hızlı çözümler
          sunarak, öncelikle faaliyetlerine toptan satış olarak başlamıştır. 7
          yılda kurduğu geniş bayi yelpazesine ek olarak 2020 yılda çağdaş
          mağaza anlayışı ile kurumsal ve parakente sektörüne de girmiştir. 2022
          yılında yenilikçi ve gelişmiş web sitesi anlayışını b2c online satış
          alanında da hizmetlerine başlayarak, binlerce teknoloji ürününü tüm
          müşterilerimize güvenli ve hızlı alışveriş deneyimi sunarak, faaliyet
          alanını en kaliteli hizmetten ve her aşamada memnuniyet için
          çalışmaktan vazgeçmeden genişletmeye devam etmektedir
        </p>
      </div>
    </div>
  </div>
</section>
<!-- about section end -->

<!--Team start-->
<section id="team" class="about-page team">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h2>Vizyonumuz</h2>
        <div class="team-4">
          <p>
            Bilişim Teknolojileri kulvarında, ülkemizdeki tüm iş ortaklarımızın
            ve müşterilerimizin ihtiyaçlarına uygun olan teknoloji ürünlerinin
            sağlayıcısı olmak, günümüz ve geleceğin değişen koşullarını ve
            sektörel değişimlerini takip ederek, güvenilir ve etik değerlerine
            bağlı bir anlayış ile Bilişim Sektörünün önde gelen firmaları
            arasında faaliyetlerine devam etmektir.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="team" class="about-page team section-b-space">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h2>Misyonumuz</h2>
        <div class="team-4">
          <p>
            Lider dünya markalarını ürün yelpazemizde bulundurarak çalışmak,
            rekabet gücümüzü arttırmak, iş ortaklarımızın ve müşterilerimizin
            tüm bilişim ürün talep ve ihtiyaçlarını en doğru şekilde analiz
            ederek, onlara en iyi hizmetini vererek karşılayan bir tedarik
            merkezi olmaktır.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Team ends-->

<!--Testimonial start-->
<section class="testimonial small-section team value-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h2 class="text-center">Değerlerimiz</h2>

        <div class="get-started-section">
          <div class="get-started-section__cards row">
            <div
              class="flip-card get-started-section__card col-md-4"
              *ngFor="let ts of testimonial"
            >
              <div class="flip-card__inner">
                <div class="flip-card__front">
                  <div class="get-started-section__card-front">
                    <div class="get-started-section__card-content">
                      <h3 class="get-started-section__card-heading">
                        <small>{{ ts.description }}</small>
                        <!--<span>Import your data</span>-->
                      </h3>
                      <!--<div
                        class="get-started-section__card-graphic"
                        style="
                          background-image: url('https://edgefrecdn.azureedge.net/welcome/sitecdn/img/get-started-import.5a8bdaf.png');
                        "
                      ></div>-->
                      <!--SVG HERE-->
                      <!---->
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class="get-started-section__card-number"><span>1</span></div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Testimonial ends-->

<app-footer-two></app-footer-two>
