<!-- Start Categories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse">
  <h3 class="collapse-block-title" (click)="collapse = !collapse">İlgili Alt Kategoriler</h3>
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <ul class="category-list">
        <div *ngIf="categoryType === 'products'; else brand">
          <li *ngFor="let category of categories">
            <a
              [routerLink]="[navService.categoryUrl]"
              [queryParams]="{
                og: createParentCatSlug(parentCategory),
                category: createSubCatSlug(category)
              }"
              [routerLinkActive]="'category-active'"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              {{ category }}
            </a>
          </li>
        </div>
        <ng-template #brand>
          <li *ngFor="let category of products">
            <a
              [routerLink]="[navService.brandUrl]"
              [queryParams]="{ marka: productBrand, category: category }"
              [routerLinkActive]="'category-active'"
            >
              {{ category }}
            </a>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
</div>
<!-- End Categories List -->
