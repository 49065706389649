<ng-container *ngIf="payPointResult">
  <div class="card p-2">
    <ul class="list-group my-cart conversion-rate">
      <li class="list-group-item d-flex lh-condensed justify-content-between align-items-center bg-light py-1 pl-1 pr-1">
        <span class="my-0 w-85">Toplam ödemeniz Türk Lirası olarak</span>
        <h4>
          <strong class="float-right pull-right conversion" >{{convertedToTl | currency:"TRY": "symbol-narrow"}}</strong>
        </h4>
      </li>
    </ul>
    <!-- <div class="input-group">
             <input type="text" class="form-control" placeholder="Promo code">
             <div class="input-group-append">
                 <button type="submit" class="btn btn-secondary">Redeem</button>
             </div>
         </div>-->
  </div>
</ng-container>
