import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { AuthManagerService } from "./auth-manager.service";
import { ApiService, userType } from "./api.service";
import { environment } from "src/environments/environment";
import { map } from "rxjs/internal/operators";
import { catchError, take } from "rxjs/operators";
import { ErrorHandlerService } from "./error-handler.service";
import { HttpClient } from "@angular/common/http";
import * as _ from "lodash";
import { TimePeriod } from "ngx-daterangepicker-material/daterangepicker.component";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(
    private authManagerService: AuthManagerService,
    private errorService: ErrorHandlerService,
    private apiService: ApiService,
    private http: HttpClient,
    private errorHandler: ErrorHandlerService
  ) {}

  getTransactions(): Observable<any> {
    const data = {
      method: "transactions",
      route: "admin",
      user: "admin",
      data: this.authManagerService.userValue,
    };
    return this.apiService.post(`${environment.apiUrl}/order`, data).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  approveOrder(order) {
    const orders = this.prepareOrder(order);
    const data = {
      method: "approveOrder",
      route: "admin",
      user: "admin",
      data: {
        orders,
      },
    };
    return this.apiService.post(`${environment.apiUrl}/order`, data).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  resendOrder(ordersData) {
    const orderData = {
      orderForDb: ordersData.orderForDb,
      orderDetails: ordersData.orderDetails,
    };
    return new Promise((resolve, reject) => {
      this.apiService
        .post(`order`, {
          method: "placeOrder",
          route: "order",
          user: "admin",
          data: {
            orders: orderData,
          },
        })
        .pipe(
          map((response) => {
            return response;
          }),
          catchError(this.errorHandler.handleError)
        )
        .subscribe(
          (response) => {
            console.log(response);
            orderData.orderDetails.orderStatus =
              response?.status === "sent" ? "Sent" : "Not Sent";
            orderData.orderDetails.orderStatusSlug = response.status;
            orderData.orderDetails.orderMessage = response.message;
            this.apiService
              .add(`${orderData.orderDetails.orderTable}`, orderData)
              .then((ref) => {
                resolve(true);
              })
              .catch(reject);
          },
          (error) => reject(error)
        );
    });
  }

  updateOrder(order: { orderStatus: string; id: string; orderTable: string }) {
    return this.apiService.update(`${order.orderTable}/${order.id}`, {
      orderStatus: order.orderStatus,
    });
  }

  getOrders(
    orderTable,
    filterQuery?: {
      paymentInfo: string;
      startDate: any;
      endDate: any;
      searchTerm?: string;
      limit?: number;
    },
    isTempTable?: boolean
  ): Observable<any> {
    const collection = isTempTable ? "tempOrders" : orderTable;

    const startDate = filterQuery.startDate;
    const endDate = filterQuery.endDate;
    return this.apiService.colWithIds$(collection, (ref) => {
      if (filterQuery && filterQuery.searchTerm) {
        return orderTable === "retailOrders" ||
          (isTempTable && orderTable === "retailOrders")
          ? ref.where("orderDetails.user.code", "==", filterQuery.searchTerm)
          : ref.where(
              "orderDetails.orderId",
              "==",
              Number(filterQuery.searchTerm)
            );
      }
      if (filterQuery && filterQuery.paymentInfo) {
        return ref
          .where("createdAt", ">=", startDate)
          .where("createdAt", "<=", endDate)
          .where("orderDetails.paymentInfo", "==", filterQuery.paymentInfo);
      }

      if (filterQuery && filterQuery.limit) {
        return ref
          .where("createdAt", ">=", startDate)
          .where("createdAt", "<=", endDate)
          .orderBy("createdAt", "desc")
          .limit(filterQuery.limit);
      }
      return filterQuery
        ? ref
            .where("createdAt", ">=", startDate)
            .where("createdAt", "<=", endDate)
        : ref.orderBy("createdAt", "desc");
    });
  }

  getCustomerOrder(userInfo) {
    return this.apiService
      .post(`${environment.apiUrl}/order`, {
        method: "getOrderByCustomerId",
        route: "shopOwner",
        user: userType,
        data: {
          id: userInfo.id,
          customerEmail: userInfo.email,
        },
      })
      .pipe(
        map((response) => {
          return response.data;
        }),
        catchError(this.errorService.handleError)
      );
  }

  getOrderDetail(orderNumber: number): Observable<any> {
    const data = {
      method: "orderDetails",
      route: "admin",
      user: userType,
      data: this.authManagerService.userValue,
      orderNumber,
    };
    return this.apiService.post(`${environment.apiUrl}/order`, data).pipe(
      map((response) => {
        return response.data;
      }),
      catchError(this.errorService.handleError)
    );
  }

  private getCustomerAccount(customerId: string) {
    const userData = this.authManagerService.userValue;

    const data = {
      method: "getVendor",
      route: userType,
      data: { customerId },
      user: userData,
    };
    return this.http.post(`${environment.apiUrl}/users`, data).pipe(
      take(1),
      map((resp: any) => {
        if (resp?.result === "OK") {
          return resp.data;
        }
        return [];
      }),
      catchError(this.errorHandler.handleError)
    );
  }

  private prepareOrder(order) {
    // siparisno, stokno, carikodu, miktar, depo
    const newOrder = [];
    const productsOrdered: any[] = order.products;
    let customerCode = ""; // order.orderTable === "retailOrders" ? order.user.code : "";

    productsOrdered.forEach((product) => {
      if (order.orderTable === "regularOrders") {
        customerCode = product.currency === "USD" ? "002009" : "002008";
      } else {
        customerCode =
          product.currency === "USD"
            ? order.user.code
            : order.user.linkedAccount;
        if (_.isEmpty(customerCode)) {
          customerCode = order.user.code;
        }
      }
      newOrder.push({
        siparisno: order.orderId,
        stokno: product.stockNo,
        carikodu: customerCode,
        miktar: product.quantity,
        depo:
          product.stockLocation === "Mağusa" ? "01 MSA DEPO" : "02 LSA DEPO",
      });
    });
    return newOrder;
  }

  public getPaynetTransactionHistory(dateRange: {
    datab: string;
    datbi: string;
  }) {
    const data = {
      method: "getPaynetTransactions",
      route: "util",
      user: "admin",
      data: {
        ...dateRange,
        show_unsucceed: true,
        untransferred: true,
        limit: 1000,
      },
    };
    return this.apiService.post(`util`, data).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
