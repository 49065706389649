<div class="mega-menu-x asbil-z-50" (mouseleave)="closeMenu()">
  <div class="navbar">
    <a
      (click)="leftMenuToggle()"
      class="btn btn-solid my-btn my-btn-hover-active"
      href="javascript:void(0)"
    >
      <div class="bar-style">
        <i aria-hidden="true" class="fa fa-bars sidebar-bar"></i>
        <span class="btn-title">TÜM ÜRÜNLER</span>
      </div>
    </a>
  </div>

  <div
    class="menu-depart container"
    [class.openSide]="navServices?.leftMenuToggle"
  >
    <div class="row">
      <div class="col-md-auto pl-0 pr-0 card border-right-0 parent-items">
        <div
          class="nav flex-column nav-pills"
          role="tablist"
          aria-orientation="vertical"
          orientation="vertical"
        >
          <div *ngFor="let menuItem of menuItems" class="nav-item">
            <a
              class="nav-link text-capitalize"
              data-toggle="pill"
              href="javascript:void(0)"
              role="tab"
              aria-controls=""
              aria-selected="true"
              [class.active]="active === menuItem.id"
              (mouseover)="toggleActive($event, menuItem)"
            >
              <span class="menu-icon fi" [ngClass]="menuItem.icon"></span>
              <span class="link-title">
                {{ menuItem.title.toLocaleLowerCase("tr-TR") }}</span
              >
              <i class="fa fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="active" class="col pl-0 card sub-menu-card">
        <div class="sub-menu">
          <ul class="sub-menu-wrapper">
            <li
              class="nav-item"
              *ngFor="let childItem of menuItem?.children"
              (click)="closeMenu()"
            >
              <a
                [queryParams]="{
                  category: childItem.title,
                  og: menuItem.title
                }"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="childItem.path"
                class="link sub-item"
                routerLinkActive="active"
              >
                <div
                  class="menu-icon"
                  *ngIf="menuItem.title !== 'ÇEVİRİCİLER'"
                  [ngStyle]="{
                    'background-image': 'url(' + childItem.icon + ')'
                  }"
                ></div>
                <span class="font-weight-600 weight-bold sub-menu-item">
                  {{ childItem.title }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="bg-blur"
  [class.active]="navServices?.leftMenuToggle"
  (click)="closeMenu()"
></div>
