export const environment = {
  production: true,
  instagram_token: "INSTA_TOKEN",
  stripe_token: "STRIPE_TOKEN",
  paypal_token: "PAYPAL_TOKEN",
  firebaseConfig: {
    apiKey: "AIzaSyCZ117BvHhPvcmK_jycSGHpcPZVxWUd8UA",
    authDomain: "asbil-online.firebaseapp.com",
    projectId: "asbil-online",
    storageBucket: "asbil-online.appspot.com",
    messagingSenderId: "264265343191",
    appId: "1:264265343191:web:86a5616bb783ba09421092",
    measurementId: "${config.measurementId}",
  },
  /* firebaseConfig: {
     apiKey: 'AIzaSyA3YmHGjCexboblAinIz3SYhbQnspVaz3M',
     authDomain: 'asbil-4e75d.firebaseapp.com',
     projectId: 'asbil-4e75d',
     storageBucket: 'asbil-4e75d.appspot.com',
     messagingSenderId: '708446768081',
     appId: '1:708446768081:web:89eecdf187c54b45972ef0',
     measurementId: 'G-1MQQG4PHKJ',
   },*/
  // apiUrl: "http://localhost/server/asbil-backend/router.php",
  apiUrlTest: "https://api.asbilonline.com/apiV2/router.php",

  apiUrl: "https://api.asbilonline.com/apiV2/router.php",
  apiUrlRoot: "https://api.asbilonline.com/apiV2/",
  isDevMode: false,
};
