<div class="row row-eq-height equal">
  <ng-container *ngIf="layoutView === 'grid-view'; else listView">
    <app-product-box-four
      *ngFor="let product of products"
      [ngClass]="grid"
      class="col-grid-box card product-card"
      [currency]="productService?.Currency"
      [product]="product"
      [loader]="true"
      (selectComponent)="selectProduct.emit($event)"
    >
    </app-product-box-four>
  </ng-container>
  <ng-template #listView>
    <div class="col-12" *ngFor="let product of products">
      <div class="card list-view">
        <div class="card-body">
          <div class="row no-gutters align-middle align-items-center">
            <div class="picture col-lg-1 col-md-1 col-sm-1 col-3 text-center">
              <div
                class="product-image-bg"
                [ngStyle]="{
                  'background-image': 'url(' + product?.images[0].src + ')'
                }"
              ></div>
            </div>
            <div class="description col-lg-7 col-md-5 col-sm-3 col-9">
              <a
                rel="nofollow"
                [routerLink]="[productDetailPage, product.STOKNO]"
                target="_blank"
              >
                {{ product.WEBACIKLAMA }}
              </a>
              <div class="storeLocation"></div>
            </div>
            <div
              class="price col-lg-2 col-md-1 col-sm-1 col-xs-1 col-4 text-center"
            >
              <!--  {{ product?.displayPrice | currency: "USD":"symbol" }}-->
              <div class="grid">
                <ng-container *ngIf="product.discountPrice; else noDiscount">
                  {{
                    product?.discountPrice
                      | currency: product?.currency:"symbol-narrow"
                  }}
                  <del *ngIf="product?.discountPrice"
                    ><span class="money">
                      {{
                        product?.displayPrice
                          | currency: product?.currency:"symbol"
                      }}</span
                    >
                  </del>
                </ng-container>
                <ng-template #noDiscount>
                  {{
                    product?.displayPrice
                      | currency: product?.currency:"symbol-narrow"
                  }}
                </ng-template>
              </div>
            </div>
            <div class="qty col-lg-1 col-md-2 col-sm-2 col-xs-2 col-4">
              <div class="stock">
                <span class="inventoryCnt"
                  >{{ product.stock }}
                  <span class="msgInStock">STOKTA</span></span
                >
              </div>
            </div>
            <div
              class="btnselect col-lg-1 col-md-1 col-sm-2 col-12 text-center"
            >
              <div
                class="btn btn-block btn-primary"
                (click)="selectProduct.emit(product)"
              >
                Seç
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
