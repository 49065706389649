import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ProductService } from '../../../services/product.service';
import { Product } from '../../../classes/product';
import { PRODUCT_DETAIL_PATH } from '../../../interfaces/interface';
import {CartService} from '../../../services/cart.service';

@Component({
  selector: 'app-cart-variation',
  templateUrl: './cart-variation.component.html',
  styleUrls: ['./cart-variation.component.scss'],
})
export class CartVariationComponent implements OnInit, OnDestroy {
  @Input() direction = 'right'; // Default Direction Right

  public products: Product[] = [];
  productPath = PRODUCT_DETAIL_PATH;

  constructor(public productService: ProductService,
              public cartService: CartService,
  ) {
    this.cartService.cartItems.subscribe(
      (response) => (this.products = response)
    );
  }

  ngOnInit(): void {
    this.cartService.OpenCart = false;
  }

  closeCart() {
    this.cartService.OpenCart = false;
  }

  get getTotal(): Observable<number> {
    return of(0);
  }

  removeItem(product: any) {
    this.cartService.removeCartItem(product);
  }

  ngOnDestroy(): void {
    this.closeCart();
  }
}
