<header [class.fixed]="stick && sticky" [ngClass]="class" class="">
  <div class="mobile-fix-option"></div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="top-menu border-top-0">
          <div class="d-flex">
            <div class="brand-logo layout2-logo">
              <a [routerLink]="['/']">
                <img [src]="themeLogo" alt="logo" class="img-fluid" />
              </a>
            </div>

            <div class="action-wrapper">
              <div class="top-header" *ngIf="topbar">
                <div class="container">
                  <div class="d-flex justify-content-between">
                    <div class="welcome-note">
                      <span class="asbil-text an-1">{{ greetings }}</span>
                    </div>
                    <div class="account-actions text-right">
                      <ul class="retailer-account">
                        <ng-container *ngIf="!user">
                          <li class="mobile-account">
                            <a routerLink="/auth/bayi-giris" class="retailer-logout"> Bayi Girisi/Ol </a>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="user">
                          <li class="mobile-account">
                            <a (click)="logOut()" href="javascript:void(0)" class="retailer-login"> Cikis Yap </a>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="settings">
                <div class="some-b">
                  <div class="btn btn-sm pc-toplama" routerLink="/shop/pc-toplama">PC TOPLAMA</div>
                </div>
                <div class="menu-right pull-right">
                  <div class="menu-extras customer-login">
                    <app-settings></app-settings>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container>
    <div class="container menu-container">
      <div class="row">
        <div class="col-lg-12">
          <div class="main-menu position-relative">
            <div class="menu-left">
              <app-left-menu [title]="'Ürünler'"></app-left-menu>
            </div>
            <div class="main-nav-center text-center">
              <app-header-search style="width: 100%"></app-header-search>
            </div>
            <div class="menu-right pull-right">
              <div class="menu-extras">
                <ul class="product-brand">
                  <li>
                    <app-menu></app-menu>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</header>

<app-cart-variation direction="right"></app-cart-variation>
