import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturesComponent implements OnInit, OnChanges {
  @Input() productFeatures: any[] = [];
  @Input() featureTitle: string;
  @Input() componentId: any;
  @Input() features: any[] = [];
  @Input() featureId: any;
  @Input() products?: any[] = [];
  @Output() featuresFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() customFilter: EventEmitter<any> = new EventEmitter<any>();

  public collapse = true;
  public yok = '';
  itemCount = 0;

  constructor() {
  }

  // get filterByKat3() {
  //     console.log(this.features);
  //     if(this.features.length > 0) {
  //         const kat3 = [...new Set(this.features.map(feature => feature.feature))];
  //         return kat3.filter(item => item.feature !== null);
  //     }else{
  //         return [];
  //     }
  // }

  ngOnInit(): void {
  }

  getId(index, feature) {
    return index + feature + Math.random();
  }

  appliedFilter(event, feature) {
    const index = this.features.indexOf(event.target.value); // checked and unchecked value
    const id = event.target.id;
    if (event.target.checked) {
      this.features.push(event.target.value);
    } // push in array cheked value
    else {
      this.features.splice(index, 1);
    } // removed in array unchecked value

    const filterValue = event.target.value.split('!')[0];
    const title = this.features.length ? { [this.featureTitle]: this.features.join(','), id } : { [this.featureTitle]: null };

    const filter2 = event.target.checked
      ? {
        added: true,
        [this.featureTitle]: filterValue,
        id,
        featureId: `${this.featureTitle}@${filterValue}`,
        val: filterValue,
        componentId: this.componentId,
      }
      : {
        added: false,
        [this.featureTitle]: filterValue,
        id,
        val: filterValue,
        featureId: `${this.featureTitle}@${filterValue}`,
        componentId: this.componentId,
      };
    this.featuresFilter.emit(title);
    this.customFilter.emit(filter2);
  }

  disabled(feature) {
    if (this.componentId === 0) {
      return false;
    }

    if (this.features.length > 0) {
      const ind = this.products.find((item) => JSON.stringify(item.features).includes(feature));

      return !_.isObject(ind);
    }

    return false;
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    // Extract changes to the input property by its name

    if (changes?.features) {
      const change: SimpleChange = changes.features;
      // console.log(changes);
    }
  }

  // check if the item are selected
  checked(item) {
    if (this.features.includes(item)) {
      return true;
    }
  }
}
