import { Injectable } from "@angular/core";
import * as localforage from 'localforage';

@Injectable({
  providedIn: "root",
})
export class LocalstorageService {
  sessionStorage = window.sessionStorage;
  localStorage = window.localStorage;

  constructor() {
    localforage.config({
      name: 'Asbil_shop'
    });
  }

  setObject(key, data: object | Array<any>) {
    this.localStorage.setItem(key, JSON.stringify(data));
  }

  setItem(key: string, data: string) {
    this.localStorage.setItem(key, data);
  }

  removeItem(key: string) {
    this.localStorage.removeItem(key);
  }

  get(key: string) {
    return localforage.getItem(key);
  }

  set(key: string, value: any) {
    return localforage.setItem(key, value);
  }

  remove(key: string) {
    return localforage.removeItem(key);
  }

  DELETE_ALL() {
    return localforage.clear();
  }

  listKeys() {
    return localforage.keys();
  }
}
