import { EventEmitter, Injectable } from "@angular/core";
import { User } from "../classes/user";
import { BehaviorSubject, Observable } from "rxjs";
import { CurrentUser } from "../classes/constants";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { catchError, map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { ErrorHandlerService } from "./error-handler.service";

const state = {
  currentUser: JSON.parse(localStorage.currentUser || "{}"),
};

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public userLoggedIn: boolean;

  constructor(
    private router: Router,
    private errorHandler: ErrorHandlerService,
    private apiService: ApiService
  ) {}

  logout() {
    // remove user from local storage and set current user to null c
    /* const user = this.userValue;
         this.userSubject.next(null);
         this.userGreetings.next("Hoşgeldiniz");
         user.userType === "customer"
           ? this.router.navigate(["/auth/uye-giris"])
           : this.router.navigate(["/auth/bayi-giris"]);*/
  }

  changePassword(values: any): Observable<any> {
    const data = {
      method: "changePassword",
      route: "customer",
      data: values,
    };
    return this.apiService
      .post(`change`, data)
      .map((response: Response) => response.json());
    // .catch(this.errorHandler.handleError);
  }

  logIn(loginCred) {
    const userType = loginCred.userType;
    return this.apiService
      .post(`users/authenticate`, {
        method: "login",
        route: userType,
        data: loginCred,
      })
      .pipe(
        map((resp) => {
          return userType === "retailer" ? resp.data : resp.user;
        })
        /* catchError(()=> {
           console.log(error);
           this.errorHandler.handleError(error);
         })*/
      );
  }
  ObserveUserData(userData) {
    return this.apiService
      .post(`users/authenticate`, {
        method: "getRetailerFullData",
        route: "admin",
        data: userData,
      })
      .pipe(
        map((resp) => {
          return userData.userType === "retailer" ? resp.data : resp.user;
        })
      );
  }

  getToken(): string {
    return localStorage.getItem("token");
  }
}
