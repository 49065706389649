import { HostListener, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, first, map, shareReplay } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';

const CACHE_SIZE = 1;

// Menu
export interface Menu {
  id?: number;
  level?: string;
  path?: string;
  title?: string;
  type?: string;
  megaMenu?: boolean;
  image?: string;
  active?: boolean;
  badge?: boolean;
  badgeText?: string;
  children?: Menu[];
  icon?: string;
  iconPath?: string;
  parentCategory?: string;
  parentSlug?: string;
  slugName?: string;
}

export interface OracleProduct {
  STOKNO: string; // "SGA02TR-60411",
  ADI: string; // "QUADRO XGAME SGA02TR-60411 I5 3.2GHZ 4GB 1TB FREED",
  WEBACIKLAMA: string; // "QUADRO XGAME SGA02TR-60411 i5 3.2GHZ 4GB 1TB FREEDOS",
  SATISFIYATI1: number; // 399,
  SATISFIYATI2: number; // 409,
  SATISFIYATI3: number; // 409,
  SATISFIYATI4: number; // 449,
  SATISFIYATI9: number; // 0,
  SPARABIRIMI1: string; // "USD",
  SPARABIRIMI2: string; // "USD",
  SPARABIRIMI3: string; // "USD",
  SPARABIRIMI4: string; // "USD",
  SPARABIRIMI9: any; // null,
  KDVDURUMU: string; // "DAHİL",
  SATISKDV: number; // 16,
  OLCUBIRIMI: string; // "ADET",
  MARKA: string; // "QUADRO",
  ANASTOK: string; // "QUADRO",
  KAT1: string; // "BİLGİSAYAR",
  KAT2: string; // "MASAUSTU PC",
  KAT3: string; // null,
  KAT4: string; // null,
  LBAKIYE: number; // 0,
  MBAKIYE: number; // 0
}

const state = {
  menu: JSON.parse(localStorage.menuItems || '[]'),
};

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public screenWidth: any;
  public leftMenuToggle: boolean;
  public mainMenuToggle: boolean;
  /*
   var '/shop/urunler'
  * */
  public categoryUrl = '/shop/urunler'; // '/shop/collection/left/sidebar',
  public brandUrl = '/shop/markalar';
  // Array
  // items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  // leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);
  public rightMenuToggle: boolean;
  public rightMobileMenuToggle: boolean;
  public mobileMenuToggle: boolean;

  public menu: Menu[];
  regEx = /[`~!@#$%^&*()_|+\-=?;:'",.<>\s\{\}\[\]\\\/]/gi;
  navSubject = new BehaviorSubject([]);
  private NavCache$: Observable<any>;

  constructor(private http: HttpClient, private apiService: ApiService, private errorHandler: ErrorHandlerService) {}

  homeNav() {
    return this.navSubject;
  }

  public get getNewMenu() {
    if (!this.NavCache$) {
      this.NavCache$ = this.NewMenu.pipe(shareReplay(CACHE_SIZE));
    }
    return this.NavCache$;
  }

  get NewMenu() {
    return this.apiService
      .post('menu', {
        method: 'getMenuCategories',
        route: 'product',
      })
      .pipe(
        first(),
        map((response) => {
          response.data.map((item) => {
            item.icon = 'flaticon-' + item.title.toLowerCase().replace(this.regEx, '');
            item.slugName = item.title.includes(' - ')
              ? item.title.replace(/\s/g, '').replace(this.regEx, '_')
              : item.title.replace(this.regEx, '_'); // parent slug
            item.children.map((subCat: any) => {
              const iconPath = `assets/images/icon/sub-items/png/${subCat.parentCategory.replace(this.regEx, '')}/`;
              if (subCat.title) {
                const icon = iconPath + subCat.title.replace(this.regEx, '').toLocaleUpperCase('tr-TR') + '.png';
                // eslint-disable-next-line max-len
                subCat.slugName = subCat.title.replace(this.regEx, '_').toLocaleUpperCase('tr-TR'); //  kat2.replace(this.regEx, '_').toLocaleUpperCase('tr-TR');
                // subCat.slugNameTwo = subCat.title.replace()
                subCat.iconPath = iconPath;
                subCat.icon = icon;
                subCat.parentSlug = subCat.parentCategory.replace(this.regEx, '_');
                return subCat;
              }
            });
            return item;
          });
          this.navSubject.next(response.data);
          return response.data;
        }),
        catchError(this.errorHandler.handleError)
      );
  }

  getMenu() {
    console.count('nav');
    return new Promise<Menu[]>((resolve, reject) => {
      // const menu: any = JSON.parse(localStorage.getItem('menu'));
      /* 	if (menu) {
          this.navSubject.next(menu.menu);
          return resolve((this.menu = menu.menu));
      } */

      this.apiService.productBroadCast().subscribe((data) => {
        let menu: Menu[] = [];
        if (data.length > 0) {
          let id = 0;
          const seen = [];
          data.forEach((el) => {
            const kat1 = el.KAT1; // parent
            const kat2 = el.KAT2; // child 1
            const kat3 = el.KAT3; // child 2
            const kat4 = el.KAT4; // child 3

            if (kat1 !== 'OUTLET') {
              if (kat1) {
                if (!seen.includes(kat1)) {
                  seen.push(kat1);
                }
                menu.push({
                  title: kat1,
                  level: 'kat1',
                  type: 'sub',
                  active: false,
                  id: id++,
                  icon: 'flaticon-' + kat1.toLowerCase().replace(this.regEx, ''),
                  children: this.addChildren(kat1, kat2, kat3, kat4, id),
                });
              }
            }
          });
          // sort and remove duplicates //
          menu = menu.sort((a, b) => new Intl.Collator().compare(a.title, b.title));
          const result: Menu[] = [
            ...menu
              .reduce((r, o) => {
                const record = r.get(o.title) || {};
                r.set(o.title, {
                  title: o.title,
                  level: o.level,
                  slugName: o.title.replace(this.regEx, ''),
                  id: o.id,
                  type: 'sub',
                  icon: o.icon,
                  children: [
                    ...(_.unionBy(_.orderBy(record.children, ['title']), 'title') || []),
                    ...o.children.filter((o1) => {
                      return _.unionBy(o1.children, 'title'); // Object.keys(o1).length !== 0;
                    }),
                  ],
                });
                return r;
              }, new Map())
              .values(),
          ];
          this.menu = result;
          localStorage.setItem(
            'menu',
            JSON.stringify({
              savedAt: Date.now(),
              menu: this.menu,
            })
          );
          this.navSubject.next(result);
          return resolve(result);
        }
      });
    });
  }

  lastChild(kat4, id) {
    const child: Menu[] = [];
    if (kat4) {
      child.push({
        title: kat4,
        level: 'kat4',
        type: 'link',
        active: false,
        id,
        path: this.categoryUrl,
      });
    }
    return child;
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  private addChildren(kat1, kat2, kat3, kat4, id) {
    const child: Menu[] = [];
    if (kat2) {
      const children = this.addChild(kat3, kat4, id);
      const iconPath = `assets/images/icon/sub-items/png/${kat1.replace(this.regEx, '')}/`;
      const icon = iconPath + kat2.replace(this.regEx, '').toLocaleUpperCase('tr-TR') + '.png';
      const slugName = kat2.replace(this.regEx, '_').toLocaleUpperCase('tr-TR');
      child.push({
        title: kat2,
        level: 'kat2',
        type: children.length > 0 ? 'sub' : 'link',
        active: false,
        path: this.categoryUrl,
        parentCategory: kat1,
        parentSlug: kat1.replace(this.regEx, ''),
        slugName,
        icon,
        iconPath,
        id,
        children,
      });
    }
    return child;
  }

  private addChild(kat3, kat4, id) {
    const child: Menu[] = [];
    if (kat3) {
      const children = this.lastChild(kat4, id);
      child.push({
        title: kat3,
        level: 'kat3',
        type: children.length > 0 ? 'sub' : 'link',
        active: false,
        id,
        path: this.categoryUrl,
        children,
      });
    }
    return child;
  }
}
