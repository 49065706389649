import { Pipe, PipeTransform } from "@angular/core";
import { isEmpty } from "lodash";

@Pipe({ name: "searchFilter" })
export class SearchFilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @param {string} origin
   * @returns {any[]}
   */
  transform(items: any[], searchText: string, origin?: string): any[] {
    if (!items) {
      return [];
    }
    if (origin === "search") {
      if (!searchText || isEmpty(searchText)) {
        return items;
      }
      searchText = searchText.toLocaleLowerCase();

      return items.filter((item) => {
        return JSON.stringify(item).toLocaleLowerCase().includes(searchText);
      });
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter((it) => {
      return it.toLocaleLowerCase().includes(searchText);
    });
  }
}
