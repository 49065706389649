<app-header [sticky]="true"></app-header>

<app-breadcrumb
  [title]="'ONLİNE İLETİŞİM'"
  [breadcrumb]="'ONLİNE İLETİŞİM'"
></app-breadcrumb>
<!--section start-->
<section class="contact-page section-b-space">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="contact-right">
          <h3 class="contact-header">İRTİBAT - MAGUSA</h3>
          <ul>
            <li>
              <div class="contact-icon">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </div>
              <div class="media-body">
                <p>
                  Eşref Bitlis Cad. Mesan Apt. C-Blok Dükkan-8, Serbest Liman
                  Yolu, Gazimağusa – KKTC
                </p>
              </div>
            </li>
            <li>
              <div class="contact-icon">
                <!--                <img src="assets/images/icon/email.png" alt="Generic placeholder image" />-->
                <img
                  src="assets/images/icon/phone.png"
                  alt="Generic placeholder image"
                />
              </div>
              <div class="media-body">
                <p>+90 548 850 98 84</p>
              </div>
            </li>
            <li>
              <div class="contact-icon">
                <img
                  src="assets/images/icon/email.png"
                  alt="Generic placeholder image"
                />
              </div>
              <div class="media-body">
                <p>info[@] asbilbilisim.com</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="contact-right">
          <h3 class="contact-header">İRTİBAT - LEFKOŞA</h3>
          <ul>
            <li>
              <div class="contact-icon text-left">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
              </div>
              <div class="media-body">
                <p>
                  Atatürk Caddesi, Candemir 7 Apt. No:26 - Dükkan A
                  Yenişehir/Lefkoşa
                </p>
              </div>
            </li>
            <li>
              <div class="contact-icon">
                <img
                  src="assets/images/icon/phone.png"
                  alt="Generic placeholder image"
                />
              </div>
              <div class="media-body">
                <p>+90 392 227 31 77</p>
              </div>
            </li>

            <li>
              <div class="contact-icon">
                <img
                  src="assets/images/icon/phone.png"
                  alt="Generic placeholder image"
                />
              </div>
              <div class="media-body">
                <p>+90 548 850 98 90</p>
              </div>
            </li>
            <li>
              <div class="contact-icon">
                <img
                  class="not-visible"
                  src="assets/images/icon/email.png"
                  alt="Generic placeholder image"
                />
              </div>
              <div class="media-body">
                <p>{{ " " }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-12">
        <form class="theme-form">
          <div class="form-row">
            <div class="col-md-6">
              <label for="name">ADINIZ</label>
              <input type="text" class="form-control" id="name" required="" />
            </div>
            <div class="col-md-6">
              <label for="email" class="text-uppercase">Soyadınız </label>
              <input
                type="text"
                class="form-control"
                id="last-name"
                required=""
              />
            </div>
            <div class="col-md-6">
              <label for="review">Telefon Numarası </label>
              <input type="text" class="form-control" id="review" required="" />
            </div>
            <div class="col-md-6">
              <label for="email">Email</label>
              <input type="text" class="form-control" id="email" required="" />
            </div>
            <div class="col-md-12">
              <label for="review">Mesajınız </label>
              <textarea
                class="form-control"
                placeholder="Write Your Message"
                id="exampleFormControlTextarea1"
                rows="6"
              ></textarea>
            </div>
            <div class="col-md-12">
              <button class="btn btn-solid asbil-text-black" type="submit">
                Mesajınızı Gönderin
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<!--Section ends-->
<app-footer-two></app-footer-two>
