/* eslint-disable @typescript-eslint/indent */
import { Injectable } from "@angular/core";
import {
  Action,
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentChangeAction,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists,
} from "@angular/fire/firestore";

import { Observable } from "rxjs";
import { catchError, map, take, tap } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import firebase from "firebase/app";
import { Product } from "../classes/product";
import { UserType } from "../interface/interface";
import { User } from "../../../shared/classes/user";
import { environment } from "../../../../environments/environment";

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

export const userType = UserType.admin;

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(
    public afs: AngularFirestore,
    public auth: AngularFireAuth,
    private http: HttpClient
  ) {}

  getUserData() {
    return JSON.parse(localStorage.getItem(userType)); // this.localForageService.get(userType).then((resp) => resp);
  }

  /*
    httpHeaders(): HttpHeaders {
      const storedUser = this.getUserData();
      let headers = {};
      if (storedUser) {
        const user = storedUser;
        if (user) {
          headers = {
            'Content-Type': 'application/json',
            'X-Auth-Token': user.token,
          };
        }
      }
      return new HttpHeaders(headers);
    }
  */

  /// Firebase Server Timestamp

  get token(): any {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        return token;
      });
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  serverTimestamp(date) {
    return firebase.firestore.Timestamp.fromDate(new Date(date));
  }

  public createId(): string {
    return this.afs.createId();
  }

  /*async httpHeader() {
    const token = await this.token;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Firebase-ID-Token': token,
        Authorization: 'Bearer ' + token,
      }),
    };
    return httpOptions;
  }*/

  async getAuthUid(): Promise<string> {
    //  const user = await (this.storage.getItem('driver'));
    //  if(user){
    //    return user.uid;
    //  }
    return (await this.auth.currentUser).uid;
  }

  /// **************
  /// Get a Reference
  /// **************

  /*	post(url: string, param: any, header?): Observable<any> {
      return this.http.post(url, param, header);
    }*/
  post(path: string, param: any, apiPoint?: string): Observable<any> {
    return this.http.post(`${apiPoint ?? environment.apiUrl}/${path}`, param);
  }
  Post(source: string, params: any, apiPoint?: string): Observable<any> {
    return this.http.post(
      `${apiPoint ?? environment.apiUrl}/${source}`,
      params
    );
  }
  Delete(source: string, params: any, apiPoint?: string): Observable<any> {
    return this.http.delete(
      `${apiPoint ?? environment.apiUrl}/${source}`,
      params
    );
  }

  get(url, params?: any) {
    return this.http.get(url, {
      params: params,
    });
  }
  Get(url, params?: any) {
    return this.http.get(`${environment.apiUrl}/${url}`, params);
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === "string" ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  /// **************
  /// Get Data
  /// **************

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === "string" ? this.afs.doc<T>(ref) : ref;
  }

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          console.log(doc);
          const data: any = doc.payload.data() as T;
          const exits = doc.payload.exists;
          const id = doc.payload.id;
          return { id, ...data, exits };
        })
      );
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((docs) => {
          return docs.map((a) => a.payload.doc.data()) as T[];
        })
      );
  }

  colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((actions: DocumentChangeAction<T>[]) => {
          return actions.map((a: DocumentChangeAction<T>) => {
            // eslint-disable-next-line @typescript-eslint/ban-types
            const data: Object = a.payload.doc.data() as T;
            const id = a.payload.doc.id;
            // @ts-ignore
            data.id = id;
            return { id, ...data };
          });
        })
      );
  }

  /// **************
  /// Write Data
  /// **************

  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    this.col(ref)
      .snapshotChanges()
      .pipe(
        take(1),
        tap((c: DocumentChangeAction<any>[]) => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Collection in ${tock}ms`, c);
        })
      )
      .subscribe();
  }

  set<T>(ref: DocPredicate<T>, data: any) {
    const timestamp = this.timestamp;
    return this.doc(ref).set(
      {
        ...data,
        updatedAt: timestamp,
        createdAt: timestamp,
      },
      { merge: true }
    );
  }

  update<T>(ref: DocPredicate<T>, data: any) {
    return this.doc(ref).update({
      ...data,
      updatedAt: this.timestamp,
    });
  }

  delete<T>(ref: DocPredicate<T>) {
    return this.doc(ref).delete();
  }

  add<T>(
    ref: CollectionPredicate<T>,
    data
  ): Promise<firebase.firestore.DocumentReference> {
    const timestamp = this.timestamp;
    return this.col(ref).add({
      createdAt: timestamp,
      ...data,
      updatedAt: timestamp,
    });
  }

  /// If doc exists update, otherwise set
  upsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const doc = this.doc(ref).snapshotChanges().pipe(take(1)).toPromise();
    return doc.then(
      (
        snap: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>
      ) => {
        return snap.payload.exists
          ? this.update(ref, data)
          : this.set(ref, data);
      }
    );
  }

  sendChargeBatchWrites(data) {
    // const timestamp = this.timestamp;

    console.log(data);
    const batch = firebase.firestore().batch();
    // sendNotificationTo: customerId,
    // notificationStatus: 'providerDeclined'
    // // Set messageMata to 'chats'

    const messageDocRef = firebase.firestore().collection("chats").doc();
    batch.set(messageDocRef, data.messageMeta);

    const chatMessageRef = messageDocRef.collection("chat-messages").doc();
    batch.set(chatMessageRef, data.message);

    // // Commit the batch
    return batch.commit();
  }

  async runTransaction(job) {
    const timestamp = this.timestamp;
    // Create a reference to the SF doc.
    const updateDocRef = firebase
      .firestore()
      .collection("direct-jobs")
      .doc(job.jobId);

    return firebase.firestore().runTransaction((transaction) => {
      return transaction
        .get(updateDocRef)
        .then((sfDoc) => {})
        .catch((err) => {
          // This will be an "population is too big" error.
          console.error(err);
          return Promise.reject("error_occurred");
        });
    });
  }

  public firestoreData(): Observable<Product[]> {
    return this.http.get<Product[]>("assets/data/asbil2021.json");
    //  return this.colWithIds$('products');
  }

  public bathWrite(col, files: []) {
    const db = firebase.firestore();
    const batch = db.batch();
    files.forEach((doc) => {
      const docRef = db.collection(col).doc(); // automatically generate unique id
      batch.set(docRef, doc);
    });

    return batch.commit();
  }

  docExists(path: string) {
    console.log(path);
    return this.afs.doc(path).valueChanges().pipe(take(1)).toPromise();
  }
}
