<ng-container *ngIf="$any(product); else selectProduct">
  <div class="row">
    <div class="col-12">
      <label>{{ componentName }}</label>
    </div>
    <div class="col-lg-8 col-md-8">
      <!--<div class="input-group">
        <input
          type="text"
          class="form-control"
          [ngClass]="{ 'focus-me': focusMe }"
          readonly
          aria-readonly="true"
          aria-label="product-name"
          aria-describedby="basic-addon2"
          [value]="product.WEBACIKLAMA"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-primary"
            type="button"
            (click)="selectComponent(componentName)"
          >
            <i class="fa fa-search"></i>
          </button>
          <button
            class="btn btn-outline-danger"
            type="button"
            (click)="removeSelected(product, componentName)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>-->
      <div class="asbil-form-control">
        <div class="asbil-input-group">
          <input
            type="text"
            class="asbil-input asbil-input-bordered asbil-w-full"
            [ngClass]="{ 'focus-me': focusMe }"
            readonly
            aria-readonly="true"
            aria-label="product-name"
            aria-describedby="basic-addon2"
            [value]="product.WEBACIKLAMA"
          />
          <button
            class="asbil-btn asbil-btn-square asbil-btn-outline asbil-btn-primary"
            type="button"
            (click)="selectComponent(componentName)"
          >
            <i class="fa fa-search"></i>
          </button>
          <button
            class="asbil-btn asbil-btn-square asbil-btn-outline asbil-btn-error asbil-ml-[2px]"
            type="button"
            (click)="removeSelected(product, componentName)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <div *ngIf="+product.stock < 1" class="invalid-feedback">Stok YOK</div>
      <div *ngIf="isPSU" class="invalid-feedback">PSU YOK</div>
      <ng-container *ngIf="componentName === pcComponents.case">
        <ng-container *ngFor="let feature of product.features">
          <ng-container
            *ngIf="
              $any(feature).name == 'OPTİK SÜRÜCÜ' &&
              $any(feature).spec === 'YOK'
            "
          >
            <div class="invalid-feedback">OPTİK SÜRÜCÜ YOK</div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <div
      class="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-6 flex asbil-items-center"
    >
      <!--<ng-container *ngIf="isQuantitySelect">
        <select
          class="custom-select d-block w-50 m-auto"
          id="country"
          [(ngModel)]="quantity"
          (change)="updateQuantity($event, product)"
        >
          <option
            [value]="qty"
            *ngFor="let qty of getQuantity(); let i = index"
          >
            {{ qty }}
          </option>
        </select>
        <div
          *ngIf="componentName === pcComponents.ram && quantity > maxRam"
          class="invalid-feedback text-center"
        >
          Maksimum Ram slot ({{ maxRam }}) sayısına ulaştınız daha fazla
          alamazsınız.
        </div>
        <ng-container *ngIf="maxMotherBoardMaxRamSizeWarning">
          <div class="invalid-feedback text-center">
            Maksimum Ram slot ({{ maxRam }}) sayısına ulaştınız daha fazla
            alamazsınız.
          </div>
        </ng-container>
      </ng-container>-->
      <ng-container *ngIf="isQuantitySelect">
        <select
          class="asbil-select asbil-select-bordered asbil-select-sm asbil-w-full asbil-max-w-xs"
          id="quantity-select"
          [(ngModel)]="quantity"
          (change)="updateQuantity($event, product)"
        >
          <option
            [value]="qty"
            *ngFor="let qty of getQuantity(); let i = index"
          >
            {{ qty }}
          </option>
        </select>
        <div
          *ngIf="componentName === pcComponents.ram && quantity > maxRam"
          class="invalid-feedback text-center"
        >
          Maksimum Ram slot ({{ maxRam }}) sayısına ulaştınız daha fazla
          alamazsınız.
        </div>
        <ng-container *ngIf="maxMotherBoardMaxRamSizeWarning">
          <div class="invalid-feedback text-center">
            Maksimum Ram slot ({{ maxRam }}) sayısına ulaştınız daha fazla
            alamazsınız.
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-lg-2 col-md-2 text-right">
      <div class="price">
        <ng-container *ngIf="product.discountPrice; else noDiscount">
          <div class="grid">
            <h5 class="mb-0">
              {{
                product?.discountPrice
                  | currency: product?.currency:"symbol-narrow"
              }}
            </h5>
            <del *ngIf="product?.discountPrice"
              ><span class="money">
                {{
                  product?.displayPrice | currency: product?.currency:"symbol"
                }}</span
              >
            </del>
          </div>
        </ng-container>
        <ng-template #noDiscount>
          <h5>
            {{
              product?.displayPrice
                | currency: product?.currency:"symbol-narrow"
            }}
          </h5>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #selectProduct>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <label>{{ componentName }}</label>
    </div>
    <div class="col-md-8">
      <!--<div class="input-group" (click)="selectComponent(componentName)">
        <input
          type="text"
          class="form-control"
          placeholder="Seç"
          readonly
          aria-readonly="true"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-primary" type="button">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>-->
      <div class="asbil-form-control">
        <div class="asbil-input-group" (click)="selectComponent(componentName)">
          <input
            type="text"
            placeholder="Seç"
            readonly
            aria-readonly="true"
            aria-label="name"
            aria-describedby="basic-addon2"
            class="asbil-input asbil-input-bordered asbil-w-[100%]"
          />
          <button
            class="asbil-btn asbil-btn-square asbil-btn-primary asbil-btn-outline"
          >
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-6"></div>
    <div class="col-lg-2 col-md-2 text-right">
      <div class="price">
        {{ 0 | currency: currency?.currency:"symbol" }}
      </div>
    </div>
  </div>
</ng-template>
