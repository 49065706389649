import { Action, createReducer, on } from '@ngrx/store';

export const cartFeatureKey = 'cart';

export interface State {
  user: any | null;
  cartDate: any | null;
  items: any | null;
}

export const initialState: State = {
  user: null,
  items: null,
  cartDate: null,
};

export const reducer = createReducer(initialState);

export const getCart = (state: State) => state;
