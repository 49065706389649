import { Component, OnInit } from '@angular/core';
import { TeamSlider, TestimonialSlider } from '../../shared/data/slider';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  constructor() {}

  public TeamSliderConfig: any = TeamSlider;
  public TestimonialSliderConfig: any = TestimonialSlider;

  // Testimonial Carousel
  public testimonial = [
    {
      image: 'assets/images/testimonial/1.jpg',
      description: 'Müşteri ve tedarikçilerimiz ile, kurulan uzun dönemli ilişkiler',
    },
    {
      image: 'assets/images/testimonial/2.jpg',
      description: 'İşin her bölümünde etik yaklaşım tarzı',
    },
    {
      image: 'assets/images/testimonial/2.jpg',
      description: 'Pazar geliştirici yapı',
    },
    {
      image: 'assets/images/testimonial/2.jpg',
      description: 'Güçlü satış organizasyonu',
    },
    {
      image: 'assets/images/testimonial/2.jpg',
      description: 'Hedef odaklı çalışma',
    },
    {
      image: 'assets/images/testimonial/2.jpg',
      description: 'Verimli satış sonrası hizmetler',
    },
    {
      image: 'assets/images/testimonial/2.jpg',
      description: 'B2C ve B2B’den alışveriş ',
    },
    {
      image: 'assets/images/testimonial/2.jpg',
      description: 'Stabil ve yüksek kalite çalışan profili',
    },
  ];
  // Team
  public team = [
    {
      image: 'assets/images/team/1.jpg',
      name: 'Mark jkcno',
      designation: 'Designer',
    },
    {
      image: 'assets/images/team/2.jpg',
      name: 'Adegoke Yusuff',
      designation: 'Content Writer',
    },
    {
      image: 'assets/images/team/3.jpg',
      name: 'John Shipmen',
      designation: 'Lead Developer',
    },
    {
      image: 'assets/images/team/4.jpg',
      name: 'Hileri Keol',
      designation: 'CEO & Founder at Company',
    },
    {
      image: 'assets/images/team/3.jpg',
      name: 'John Shipmen',
      designation: 'Lead Developer',
    },
  ];

  ngOnInit(): void {}
}
