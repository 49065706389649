import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Product } from "../../classes/product";
import { ProductService } from "../../services/product.service";
import { AccountService } from "../../services";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "../../classes/user";
import { ApiService } from "../../services/api.service";
import { AppState, selectAuthState } from "../../../_store/app.states";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { initialState } from "../../../_store/reducers/auth.reducer";
import { LogOut } from "../../../_store/actions/auth.action";

declare var anime: any;

@Component({
  selector: "app-header",
  templateUrl: "./header-one.component.html",
  styleUrls: ["./header-one.component.scss"],
})
export class HeaderOneComponent
  implements OnInit, AfterViewChecked, AfterViewInit
{
  // @ViewChild('headerProductSearch') headerProductSearch: ElementRef;

  @Input()
  class: string;
  @Input() themeLogo = "assets/images/logos/logo_blue.png"; // Default Logo
  // @Input() themeLogo: string = 'assets/images/icon/logo-100.png' // Default Logo

  @Input() topbar = true; // Default True
  @Input() sticky = false; // Default false

  searchProducts: Product[] = [];
  public searchText;
  public stick = false;
  isSearch: boolean;
  notFound: boolean;
  public notesText: string;
  subject = new Subject();
  public greetings = "";
  user: User;
  // @HostListener Decorator
  searchModel: any;
  products: Product[] = [];
  getState: Observable<any>;

  constructor(
    private productService: ProductService,
    private changeRef: ChangeDetectorRef,
    public accountService: AccountService,
    private router: Router,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private store: Store<AppState>
  ) {
    this.greetings = this.accountService.greetings;
    this.user = this.accountService.user;
    // this.route.queryParams.subscribe((params) => {
    //   console.log(params);
    // });
  }

  async ngOnInit() {
    // this.getState.subscribe((state: typeof initialState) => {
    //   if (!_.isEmpty(state)) {
    //     if (state.isAuthenticated) {
    //       const user = state.user;
    //       this.user = user;
    //       if (user && user.userType === 'customer') {
    //         this.greetings = user?.firstName ? `Merhaba, ${user?.firstName}` : `Hoşgeldiniz`;
    //       } else {
    //         this.greetings = user?.ADI ? `Merhaba, ${user?.ADI}` : `Hoşgeldiniz`;
    //       }
    //     } else {
    //       this.user = null;
    //       this.greetings = 'Hoşgeldiniz';
    //     }
    //   }
    // });
  }

  ngAfterViewInit(): void {
    // Wrap every letter in a span
    // .add({
    //   targets: '.an-1',
    //   opacity: 0,
    //   duration: 1000,
    //   easing: 'easeOutExpo',
    //   delay: 1000,
    // });
  }

  ngAfterViewChecked() {}

  async onSearchInput(event) {
    console.log(event);
    this.isSearch = true;
    const searchTerm = event.target.value as string;
    if (!searchTerm) {
      return (this.searchProducts = []);
    }
    // show also the checked items
    this.searchProducts = this.products.filter((item) => {
      return JSON.stringify(item)
        .toLocaleLowerCase()
        .includes(searchTerm.toLocaleLowerCase());
    });
  }

  logOut(): void {
    // this.accountService.logout();
    this.accountService.logOut();
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const num =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.stick = num >= 150 && window.innerWidth > 400;
  }
}
