<div
  class="collection-collapse-block"
  [class.open]="collapse"
  *ngIf="categoryBrands.length"
>
  <h3 class="collapse-block-title" (click)="collapse = !collapse">Markalar</h3>
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <div
        class="custom-control custom-checkbox collection-filter-checkbox"
        *ngFor="let brand of categoryBrands; let i = index"
      >
        <input
          type="checkbox"
          [value]="brand"
          [checked]="checked(brand)"
          [id]="'br-' + brand"
          (change)="appliedFilter($event)"
          class="custom-control-input"
        />
        <label class="custom-control-label" [for]="'br-' + brand">{{
          brand
        }}</label>
      </div>
    </div>
  </div>
</div>
