<div class="img-wrapper">
	<div class="lable-block">
		<span class="lable3" *ngIf="product.new">new</span>
		<span class="lable4" *ngIf="product.sale">on sale</span>
	</div>
	<div class="front">
		<a
			[routerLink]="[
				'/shop/product/left/sidebar/',
				product.title.replace(' ', '-')
			]"
		>
			<img
				[defaultImage]="
					ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'
				"
				[lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src"
				class="img-fluid lazy-loading"
				alt=""
			/>
		</a>
	</div>
</div>
<div class="product-detail text-center">
	<a
		[routerLink]="[
			'/shop/product/left/sidebar/',
			product.title.replace(' ', '-')
		]"
	>
		<h6>{{ product.title | titlecase }}</h6>
	</a>
	<h4>
		{{
			product?.price * currency?.price
				| discount: product
				| currency: currency?.currency:'symbol'
		}}
		<del *ngIf="product?.discount"
			><span class="money">
				{{
					product?.price * currency?.price
						| currency: currency?.currency:'symbol'
				}}</span
			></del
		>
	</h4>
</div>

<app-quick-view
	#quickView
	[product]="product"
	[currency]="currency"
></app-quick-view>
<app-cart-modal
	#cartModal
	[product]="product"
	[currency]="currency"
	*ngIf="cartModal"
></app-cart-modal>
