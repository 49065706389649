import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LocationsService } from '../../../shared/services/locations.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { ToastService } from '../../../shared/services/toast.service';

@Component({
  selector: 'app-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.scss'],
})
export class BillingAddressComponent implements OnInit {
  @Input() title: string;
  @Input() type: string;
  @Input() patchValues?: any;
  @Output() getBillingAddress: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('districtInstance', { static: true })
  districtInstance: NgbTypeahead;
  addressForm: FormGroup;
  cityModel: any;
  districtModal: any;
  sameAddress: any;
  cities = [];
  districts: any[] = [];

  constructor(
    private fb: FormBuilder,
    private locationService: LocationsService,
    private toastService: ToastService
  ) {
    this.addressForm = this.fb.group({
      firstname: [
        '',
        [
          Validators.required,
        ],
      ],
      lastname: [
        '',
        [
          Validators.required,
        ],
      ],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      address: ['', [Validators.required]],
      addressTwo: [''],
      city: ['', Validators.required],
      district: ['', Validators.required],
      postalcode: [''],
    });
  }

  ngOnInit() {
    this.locationService.locations.subscribe((cities: any) => {
      this.cities = cities || [];
    });

    if (this.patchValues && this.type === 'shipping') {
      console.log(this.patchValues);
    }

    if (this.type === 'shipping') {
      this.addressForm.addControl('sameAddress', new FormControl(false));
    }
  }


  citySelected(event) {
    const city = this.cities.find(item => item.name === event.target.value);
    this.districts = city.districts;
    this.districtModal = null;
  }

  districtSelected(event) {
    console.log(event);
    /*console.log(this.cityModel);
    this.addressForm.patchValue({
      city: this.cityModel.name,
      district: event.item.name,
    });*/
  }

  deliveryOption(event) {
    const option = '';
  }

  billSameAsShipping(event) {
    this.addressForm.patchValue({
      sameAddress: this.sameAddress,
    });
  }

  saveAddress() {
    this.addressForm.markAllAsTouched();

    if (this.addressForm.invalid) {
      this.toastService.errorToast('Lütfen adres formunu doldurun');
      return;
    }
    this.getBillingAddress.emit(this.addressForm.value);
  }
}
