import { Component, OnInit } from '@angular/core';
import { Menu, NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[];
  public brands: any[];
  public searchText = '';

  constructor(private router: Router, public navServices: NavService, private productService: ProductService) {
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
    this.productService.brand.subscribe((data) => {
      this.brands = _.orderBy(data);
    });
  }

  rightMenuToggle(getBrands?: string): void {
    console.log(this.navServices.rightMenuToggle);
    this.navServices.rightMenuToggle = !this.navServices.rightMenuToggle;
  }

  showProductsByBrand(brand) {
    this.router
      .navigate([this.navServices.brandUrl], {
        queryParams: { marka: brand },
      })
      .then(() => {
        this.navServices.rightMenuToggle = false;
      })
      .catch((e) => console.log(e));
  }

  onHover(menuItem) {
    if (window.innerWidth > 1200 && menuItem) {
      document.getElementById('unSetter').classList.add('sidebar-unset');
    } else {
      document.getElementById('unSetter').classList.remove('sidebar-unset');
    }
  }
}
