import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* firebase imports */
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { LightboxModule } from 'ngx-lightbox';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';

import { environment } from '../environments/environment';
import { AccountComponent } from './account/account.component';
import { ErrorInterceptor } from './shared/interceptors/errorInterceptor';
import { JwtInterceptor } from './shared/interceptors/jwtInterceptor';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { DEFAULT_CONFIG, Driver, NgForageOptions } from 'ngforage';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './_store/effects/auth.effects';
import { AuthService } from './shared/services/auth.service';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { reducers } from './_store/app.states';
import * as auth from './_store/reducers/auth.reducer';
import { PaynetRedirectResponseComponent } from './_paynet/paynet-redirect-response/paynet-redirect-response.component';
import { ResponsiveModule } from 'ngx-responsive';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/analytics';
// import { AngularFirePerformanceModule } from '@angular/fire/performance';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['auth', 'cart'], rehydrate: true })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent, ShopComponent, AccountComponent, PaynetRedirectResponseComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    // AngularFirePerformanceModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([AuthEffects]),
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LightboxModule,
    NgxSkeletonLoaderModule,
    SharedModule,
    AppRoutingModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    ResponsiveModule,
  ],
  providers: [
    {
      provide: DEFAULT_CONFIG,
      useValue: {
        name: 'asbilDb',
        driver: [
          // defaults to indexedDB -> webSQL -> localStorage
          Driver.INDEXED_DB,
          Driver.LOCAL_STORAGE,
        ],
      } as NgForageOptions,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthService,
    ScreenTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
