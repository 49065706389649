<!--footer section -->
<footer [ngClass]="class" class="footer-bg">
  <div class="custom-footer" *ngIf="mainFooter">
    <div class="container">
      <section class="section-b-space border-b">
        <div class="row footer-theme2 justify-content-center">
          <div class="col-lg-5 p-set">
            <div
              class="footer-contant d-flex justify-content-center align-content-center"
              style="height: 100%; margin-bottom: unset"
            >
              <div class="footer-logo custom-logo" id="footerlogo">
                <img [src]="themeLogo" alt="logo" />
                <div class="social-white">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/ASB%C4%B0L-Bili%C5%9Fim-Teknolojileri-452476898478709"
                        target="_blank"
                        ><i class="fa fa-facebook" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)"
                        ><i class="fa fa-twitter" aria-hidden="true"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:void(0)"
                        ><i class="fa fa-instagram" aria-hidden="true"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 subscribe-wrapper">
            <div class="first-footer footer-title d-flex">
              <h4>Müşteri Hizmetleri</h4>
            </div>
            <div class="footer-contant">
              <ul class="contact-details corporate text-left">
                <li>
                  <a routerLink="/c/teslimat-ve-iade-sartlari"
                    >Teslimat ve İade Şartları</a
                  >
                </li>
                <li>
                  <a routerLink="/c/mesafeli-satis-sozlesmesi"
                    >Mesafeli Satış Sözleşmesi</a
                  >
                </li>
                <li>
                  <a routerLink="/c/uyelik-sozlemesi">Üyelik Sözleşmesi</a>
                </li>
                <li>
                  <a routerLink="/c/gizlilik-ve-guvenlik">
                    Gizlilik ve Güvenlik</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="first-footer footer-title d-flex">
              <h4>KURUMSAL BİLGİLER</h4>
            </div>
            <div class="footer-contant">
              <ul class="contact-details text-left pl-0">
                <li><a routerLink="/hakkimizda">Hakkımızda</a></li>
                <li><a routerLink="/magazalar">Mağazalar</a></li>
                <li><a routerLink="/iletisim">İletişim</a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="sub-footer">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-md-6 col-sm-12">
            <div class="footer-end">
              <p>
                Asbil Bilişim LTD.
                <i class="fa fa-copyright" aria-hidden="true"></i>
                {{ today | date: "y" }}. Her hakkı saklıdır.
              </p>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12">
            <div class="payment-card-bottom">
              <ul>
                <li>
                  <a><img src="assets/images/icon/visa.png" alt="" /></a>
                </li>
                <li>
                  <a><img src="assets/images/icon/mastercard.png" alt="" /></a>
                </li>
                <li>
                  <a><img src="assets/images/icon/paypal.png" alt="" /></a>
                </li>
                <li>
                  <a
                    ><img src="assets/images/icon/american-express.png" alt=""
                  /></a>
                </li>
                <li>
                  <a><img src="assets/images/icon/discover.png" alt="" /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="lines">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</footer>
<!--footer section end -->
