import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { fromEvent, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../services/product.service';
import { Product } from '../../classes/product';
import { Router } from '@angular/router';
import { AccountService } from '../../services';
import { User } from '../../classes/user';
import { PRODUCT_DETAIL_PATH } from '../../interfaces/interface';
import { delay } from 'rxjs/operators';
import { AppState, selectAuthState } from '../../../_store/app.states';
import { Store } from '@ngrx/store';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  @ViewChild('sideCart') sideCart: ElementRef;
  public cartItems: Product[] = [];
  public search = false;

  public languages = [
    {
      name: 'English',
      code: 'en',
    },
    {
      name: 'French',
      code: 'fr',
    },
  ];

  public currencies = [
    {
      name: 'Euro',
      currency: 'EUR',
      price: 0.9, // price of euro
    },
    {
      name: 'Rupees',
      currency: 'INR',
      price: 70.93, // price of inr
    },
    {
      name: 'Pound',
      currency: 'GBP',
      price: 0.78, // price of euro
    },
    {
      name: 'Dollar',
      currency: 'USD',
      price: 1, // price of usd
    },
  ];
  user: User;
  getState: Observable<any>;
  productDetailPath = PRODUCT_DETAIL_PATH;

  constructor(@Inject(PLATFORM_ID) private platformId: object, private translate: TranslateService, private router: Router, public accountService: AccountService, public productService: ProductService, public cartService: CartService, private store: Store) {
    this.cartService.cartItems.subscribe((response) => {
      if (response) {
        this.cartItems = response;
      } else {
        // this.cartItems = []
      }
    });
    this.getState = this.store.select(selectAuthState);
  }

  ngOnInit(): void {
    this.getState.subscribe((state) => {
      this.user = state.user;
    });
  }

  searchToggle() {
    this.search = !this.search;
  }

  navigateUserAccount() {
    this.router.navigate(['/account']).catch((e) => {
      console.log(e);
    });
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
    }
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency;
  }

  myAccount() {
    // check account service
    this.router.navigate(['/pages/login']).catch((e) => console.log(e));
  }

  navigate(link: string) {
    // routerLink="/auth/uye-ol"
    this.router
      .navigate(['/auth/uye-ol'])
      .then((ee) => console.log(ee))
      .catch((e) => console.log(e));
  }

  openSideCart() {
    this.productService.OpenCart = true;
  }
}
