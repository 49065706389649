import { createAction, props } from "@ngrx/store";

export enum AuthActionTypes {
  LOGIN = "[Auth] Login",
  LOGIN_SUCCESS = "[Auth] Login Success",
  LOGIN_FAILURE = "[Auth] Login failure",
  LOGOUT = "[Auth] Logout",
  RESET = "[Auth] Reset Errors",
  LOGIN_REDIRECTS = "[Auth] Redirect",
  USER_LISTENER = "[Auth] USER LISTENER",
  USER_LISTENER_SUCCESS = "[Auth] USER LISTENER SUCCESS",
}

export const LogIn = createAction(AuthActionTypes.LOGIN, props<{ any }>());

export const LogInSuccess = createAction(
  AuthActionTypes.LOGIN_SUCCESS,
  props<any>()
);

export const LogInFailure = createAction(
  AuthActionTypes.LOGIN_FAILURE,
  props<any>()
);

export const UserListener = createAction(
  AuthActionTypes.USER_LISTENER,
  props<any>()
);

export const UserListenerSuccess = createAction(
  AuthActionTypes.USER_LISTENER_SUCCESS,
  props<any>()
);

export const LogInErrorReset = createAction(AuthActionTypes.RESET);
export const LogOut = createAction(AuthActionTypes.LOGOUT);
export const LoginRedirect = createAction(AuthActionTypes.LOGIN_REDIRECTS);
