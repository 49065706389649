import { Injectable } from "@angular/core";
import { isArray, isEmpty } from "lodash";
import { catchError, map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { ErrorHandlerService } from "./error-handler.service";
import {
  CONVERSION_KEY,
  DELIVERY_FEE,
  MAX_PRICE_FOR_DELIVERY,
} from "../interfaces/interface";
import { Title } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  deliveryFeeEvent$ = new BehaviorSubject(null); // detects delivery option change
  emitIsDeliveryFee$ = new BehaviorSubject(0); // holds a reference to delivery amount

  constructor(
    private apiService: ApiService,
    private errorHandler: ErrorHandlerService,
    private title: Title
  ) {}

  public setTitle(newTile: string) {
    this.title.setTitle(newTile);
  }

  getUSDConversionRate(amountToConvert: number): Observable<number> {
    return this.apiService
      .post(`util/currency`, {
        method: "getUSDConversionRate",
        route: "util",
        data: {
          amountToConvert,
        },
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        map((response) => {
          if (response.result === "OK") {
            return response.data as number;
          } else {
            return 0;
          }
        })
      );
  }

  getSaveConversion() {
    return this.apiService
      .post("util", {
        method: "getConversionRate",
        route: "util",
        user: "customer",
        data: {},
      })
      .pipe(
        map((response) => {
          if (response.result === "OK") {
            response.data.timestamp = new Date();
          }
          localStorage.setItem(CONVERSION_KEY, JSON.stringify(response));
        }),
        catchError(this.errorHandler.handleError)
      );
  }

  getConversionRate(priceData) {
    return this.apiService
      .post("util", {
        method: "getUSDConversionRate",
        route: "util",
        user: "customer",
        data: {
          priceData,
        },
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.errorHandler.handleError)
      );
  }

  // convertPricesToTl()

  localeLowerCase(value): string {
    return isEmpty(value) ? "" : value.toLocaleLowerCase("tr-TR");
  }

  arrangeCategoryItems(
    categoryTitle: any,
    productWithFeatures,
    preferredOrder: any[]
  ) {
    return isEmpty(preferredOrder)
      ? productWithFeatures
      : productWithFeatures.sort(
          (a, b) =>
            preferredOrder.indexOf(a.name) - preferredOrder.indexOf(b.name)
        );
  }

  private sortSubCategories(items: any[], sortArray: any[]) {
    return items.sort(
      (a, b) => sortArray.indexOf(a.name) - sortArray.indexOf(b.name)
    );
  }

  initDeliverFee(totalPrice: number, isPickUp = false) {
    this.emitIsDeliveryFee$.next(
      !isPickUp
        ? totalPrice > MAX_PRICE_FOR_DELIVERY
          ? 0.0
          : DELIVERY_FEE
        : 0.0
    );
  }

  addDeliveredAmount(totalAmount: any) {
    return totalAmount > MAX_PRICE_FOR_DELIVERY
      ? totalAmount
      : totalAmount + DELIVERY_FEE;
  }
}
