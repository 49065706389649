import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { Observable } from "rxjs";
import { User, UserType } from "../classes/user";

import { catchError, map } from "rxjs/operators";
import { ErrorHandlerService } from "./error-handler.service";
import { Store } from "@ngrx/store";
import { getUser, initialState } from "../../_store/reducers/auth.reducer";
import * as _ from "lodash";
import { AppState, selectAuthState } from "../../_store/app.states";
import { LogOut } from "../../_store/actions/auth.action";
import { isEmpty } from "lodash";

// const userType = UserType;
@Injectable({
  providedIn: "root",
})
export class AccountService {
  public user: User;
  public greetings: string;
  public getState: Observable<any> = new Observable<any>();

  constructor(
    private errorHandler: ErrorHandlerService,
    private apiService: ApiService,
    private store: Store
  ) {
    this.getState = this.store.select(selectAuthState);

    this.getState.subscribe((state: typeof initialState) => {
      if (!_.isEmpty(state)) {
        if (state.isAuthenticated && !isEmpty(state.user)) {
          const user = state.user;
          console.log(user);
          if (user.userType === "customer") {
            this.user = user;
            this.greetings = user?.firstName
              ? `Merhaba, ${user?.firstName}`
              : `Hoşgeldiniz`;
          } else {
            this.greetings = user?.ADI
              ? `Merhaba, ${user?.ADI}`
              : `Hoşgeldiniz`;
            this.user = user;
          }
        } else {
          this.user = null;
          this.greetings = "Hoşgeldiniz";
        }
      }
    });
  }

  registerConsumer(user: User, userType: UserType): Observable<any> {
    user.userType = userType;
    user.address = "";
    return this.apiService
      .post(`users/register`, {
        method: "register",
        data: user,
        route: "customer",
      })
      .pipe(catchError(this.errorHandler.handleError));
  }

  registerRetailer(user: User, userType): Observable<any> {
    user.userType = userType;
    user.address = "";
    return this.apiService
      .post(`users`, {
        method: "register",
        data: user,
        route: "retailer",
      })
      .pipe(catchError(this.errorHandler.handleError));
  }

  getAccountInfo(payload) {
    return this.apiService.post(`accountinfo`, {
      method: "validate",
      route: "customer",
      data: payload,
      user: payload.userType,
    });
  }

  updateRetailer(value: any) {
    value.userType = UserType.retailer;
    value.address = "";
    return this.apiService
      .post(`users`, {
        method: "recoverRetailerAccount",
        data: value,
        route: value.userType,
      })
      .pipe(catchError(this.errorHandler.handleError));
  }

  sendPasswordReset(value) {
    return this.apiService
      .post("users", {
        method: "resetPassword",
        data: value,
        route: value.userType,
      })
      .pipe(catchError(this.errorHandler.handleError));
  }

  getAccountState() {
    return this.store.select(getUser).pipe(
      map((res: any) => {
        if ("auth" in res) {
          const userData = {
            ...res.auth,
            user: null,
            userType: null,
          };
          if (!isEmpty(res.auth.user)) {
            userData.user = res.auth.user;
            userData.userType = res?.auth?.user?.userType;
          } else {
            userData.isAuthenticated = false;
          }
          return userData;
        }
      })
    );
  }

  checkUser() {
    return new Promise((resolve, reject) => {
      const sd = this.store.select(getUser);
      sd.subscribe(
        (dt: any) => {
          if ("auth" in dt) {
            resolve(dt.auth);
          } else {
            reject(null);
          }
        },
        (e: any) => reject(null)
      );
    });
  }

  signInAnonymously() {
    this.apiService.signInAnnonymous();
  }

  logOut(): void {
    // this.accountService.logout();
    this.store.dispatch(LogOut());
    this.user = null;
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
