import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LocationsService{
    constructor(private apiService: ApiService) {
    }

    get locations(){
        return this.apiService.get('../../../assets/data/locations_new.json')
            .pipe(
                map((data) => {
            return data;
        }));


    }
}
