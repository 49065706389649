import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import * as _ from 'lodash';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  @Input() categories: string[] = [];

  @Input() products: Product[] = [];
  @Input() parentCategory: string;
  @Input() parentCategoryOg: string;
  @Input() categoryType: string;
  @Input() productBrand: string;
  productsCategory: string;

  public collapse = true;

  constructor(public productService: ProductService, public navService: NavService) {
    this.productService.Category.subscribe((value) => {
      this.products = value;
    });
  }

  createParentCatSlug(kt) {
    if (!kt) {
      return;
    }
    return kt;
  }

  createSubCatSlug(category) {
    if (!category) {
      return;
    }
    return category;
    // return category.replace(this.navService.regEx, '_').toLocaleLowerCase('tr-TR');
  }

  filterbyCategory(c) {
    const category = [...new Set(this.products.map((product) => product.KAT2))];
    return _.orderBy(category);
  }

  ngOnInit(): void {}
}
