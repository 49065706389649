import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Product } from "../../classes/product";
import { User, UserType } from "../../classes/user";

@Component({
  selector: "app-display-discount",
  templateUrl: "./display-discount.component.html",
  styleUrls: ["./display-discount.component.scss"],
})
export class DisplayDiscountComponent implements OnInit {
  @Input() product: Product;
  @Input() origin?: any;
  @Input() updatedQuantity?: any = {
    quantity: 1,
    product: "",
  };
  @Input() user?: any;
  quantityAdded = 1;

  constructor() {}

  ngOnInit(): void {
    if (this.origin && this.origin === "checkOut") {
      this.quantityAdded = +this.product.quantity;
    } else if (this.product) {
      this.quantityAdded = +this.product.quantity;
    }
  }

  convertToTl(product) {
    return product.displayPrice + 2;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.updatedQuantity) {
      if (
        this.updatedQuantity?.product &&
        this.product.STOKNO === this.updatedQuantity.product
      ) {
        this.quantityAdded = this.updatedQuantity.quantity;
      }
    }
  }

  checkUser(user: User, origin: string) {
    if (user) {
      if (!user?.isAuthenticated && origin === "checkOut") {
        return true;
      }
      return (
        user.isAuthenticated &&
        user.userType === UserType.customer &&
        origin === "checkOut"
      );
    }

    return false;
  }
}
