import {Injectable} from '@angular/core';
// import firebase from 'firebase/app';
import {ApiService} from './api.service';
import {Product} from '../classes/product';

@Injectable({
    providedIn: 'root',
})
export class PcBuilderService {

    constructor(private apiService: ApiService) {
    }


    set setBuildComponent(component){
        localStorage.setItem(component.componentName, JSON.stringify(component.component));
    }

    getPcBuildComponent(name: string){
        return JSON.parse(localStorage.getItem(name));
    }

    removeBuildComponent(name: string){
        localStorage.removeItem(name);
    }

    set setPcBuild(product: Product[]){
        localStorage.setItem('pcBuild', JSON.stringify(product));
    }

    get getPcBuild() {
       return  JSON.parse(localStorage.getItem('pcBuild')) as Array<Product>;
    }
    removeBuild(){
        localStorage.removeItem('pcBuild');
    }
}
