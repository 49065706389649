export enum PC_COMPONENTS {
  cpu = "CPU",
  motherBoard = "ANAKART",
  cooler = "CPU FANI",
  ram = "BELLEK",
  graphicsCard = "EKRAN KARTI",
  hdd = "HDD",
  ssdSata = "SSD SATA",
  ssdNvm = "M2 NVME PCIE",
  case = "KASA",
  powerSupply = "POWER SUPPLY",
  dvdDrive = "OPTİK",
  mouse = "MOUSE",
  mousePad = "MOUSE PAD",
  keyBoard = "KLAVYE",
  mouseKeyboardSet = "KLAVYE MOUSE SET",
  gamingMouse = "GAMING MOUSE",
  gamingKeyboard = "GAMING KLAVYE",
  gamingHeadSets = "GAMING KULAKLIK - MİKROFON",
  gamingMousePage = "GAMING MOUSE PAD",
  monitor = "MONİTÖR",
  speaker = "HOPARLÖR",
  webCam = "WEBCAM",
}
