<div *ngIf="!loader" class="product-inner-wrapper">
  <div *ngIf="product.images" class="img-wrapper">
    <!--  <div class="lable-block">
        <span class="lable3" *ngIf="product.new">new</span>
        <span class="lable4" *ngIf="product.sale">on sale</span>
      </div>-->
    <div class="front">
      <a [routerLink]="['/shop/product/no/sidebar/', product.STOKNO]">
        <img
          [lazyLoad]="product?.images.length > 0 ? product?.images[0].src : ImageSrc"
          alt="{{ product.ADI }}"
          class="img-fluid lazy-loading"
        />
      </a>
    </div>

    <ul *ngIf="thumbnail" class="product-thumb-list">
      <li *ngFor="let image of product.images" [class.active]="ImageSrc == image.src" class="grid_thumb_img">
        <a (mouseover)="ChangeVariantsImage(image.src)" href="javascript:void(0)">
          <img [lazyLoad]="image.src" />
        </a>
      </li>
    </ul>
  </div>
  <div class="product-detail">
    <div>
      <!--      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>-->

      <!-- Grid view -->
      <div class="grid-view-content">
        <div class="stock">
          <h6>
            <a [routerLink]="['/shop/product/no/sidebar/', product?.STOKNO]" class="">
              {{ product?.WEBACIKLAMA }}
            </a>
          </h6>

          <div class="row align-items-center">
            <div class="col-md-6">
              <h4>
                {{ product?.SATISFIYATI3 | currency: currency?.currency:'symbol' }}
                <!--            <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>-->
              </h4>
            </div>
            <div class="col-md-6">
              <span class="money"> Stok : {{ product?.stock }} </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div (click)="CartModal.openModal(product)" class="btn btn-sm btn-outline-primary btn-block add-to-cart">
              Sepete Ekle <i class="fa fa-shopping-cart"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- List view -->
      <div class="row list-view-inner align-items-center">
        <div class="col-md-6">
          <a [routerLink]="['/shop/product/no/sidebar/', product?.STOKNO]" class="list-view-product-title">
            {{ product?.WEBACIKLAMA }}
          </a>
        </div>
        <div class="col-md-3">
          <div class="row">
            <div class="col">
              <span class="money">
                {{ product?.SATISFIYATI3 | currency: currency?.currency:'symbol' }}
                <!--            <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>-->
              </span>
            </div>
            <div class="col">
              <span class=""> Stok : {{ product?.stock }} </span>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div (click)="CartModal.openModal(product)" class="btn btn-sm btn-orange buy-btn">
            Sepete Ekle <i class="fa fa-shopping-cart"></i>
          </div>
        </div>
      </div>

      <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
         <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
           (click)="ChangeVariants(color, product)">
         </li>
       </ul>-->
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [currency]="currency" [product]="product"></app-quick-view>
<app-cart-modal #cartModal *ngIf="cartModal" [currency]="currency" [product]="product"></app-cart-modal>
