<div
  *ngIf="!loader"
  class="asbil-card asbil-card-side product-card asbil-bg-base-100 asbil-shadow-xl asbil-mb-1.5"
>
  <ng-container *ngIf="product">
    <figure (click)="openProductPage(product?.STOKNO)">
      <img
        [src]="product?.images.length > 0 ? product?.images[0].src : ImageSrc"
        alt="{{ $any(product.images[0])?.alt }}"
        class="lazy-loading product-image"
      />
    </figure>
    <div
      class="asbil-card-body asbil-gap-0 asbil-pt-0 asbil-pb-0 asbil-pr-2 asbil-pl-2"
    >
      <div class="asbil-card-title">
        <a
          rel="nofollow"
          [routerLink]="[productDetailPath, product?.STOKNO]"
          target="_blank"
        >
          {{ product.WEBACIKLAMA }}
        </a>
      </div>
      <ng-container *ngIf="isUser; else userFailSafePrice">
        <div class="price">
          <ng-container *ngIf="product.discountPrice; else noDiscount">
            {{
              product?.discountPrice
                | currency: product?.currency:"symbol-narrow"
            }}
            <del *ngIf="product?.discountPrice"
              ><span class="money">
                {{
                  product?.displayPrice | currency: product?.currency:"symbol"
                }}</span
              >
            </del>
          </ng-container>
          <ng-template #noDiscount>
            {{
              product?.displayPrice
                | currency: product?.currency:"symbol-narrow"
            }}
          </ng-template>
        </div>
      </ng-container>
      <ng-template #userFailSafePrice>
        <div class="price">
          <ng-container *ngIf="product.discountPrice; else noDiscount">
            {{
              product?.discountPrice
                | currency: product?.currency:"symbol-narrow"
            }}
            <del *ngIf="product?.discountPrice"
              ><span class="money">
                {{
                  product?.displayPrice | currency: product?.currency:"symbol"
                }}</span
              >
            </del>
          </ng-container>
          <ng-template #noDiscount>
            {{
              product?.displayPrice
                | currency: product?.currency:"symbol-narrow"
            }}
          </ng-template>
        </div>
      </ng-template>

      <div
        class="qty asbil-flex asbil-justify-between asbil-items-center asbil-mb-[8px]"
      >
        <div class="stock">Stok: {{ product.stock }}</div>
        <button
          [disabled]="product?.stock === 0"
          class="asbil-btn asbil-btn-outline asbil-btn-primary asbil-btn-sm"
          (click)="addToCart(product, cartModal)"
        >
          Sepete Ekle
        </button>
      </div>
      <!-- <div class="asbil-card-actions asbil-justify-end asbil-mb-1.5">
        <button
          [disabled]="product?.stock === 0"
          class="asbil-btn asbil-btn-outline asbil-btn-primary asbil-btn-sm"
          (click)="addToCart(product, cartModal)"
        >
          Sepete Ekle
        </button>
      </div>-->
    </div>
  </ng-container>
</div>

<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view
  #quickView
  [currency]="currency"
  [product]="product"
></app-quick-view>
<app-cart-modal
  #cartModal
  *ngIf="cartModal"
  [currency]="currency"
  [product]="product"
></app-cart-modal>
