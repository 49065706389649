import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    document.getElementById('body').style.height = '100vh';
    document.getElementById('body').style.overflow = 'hidden';
  }
}
